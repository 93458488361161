.Navbar {
  z-index: 5;
}
.Navbar .navbar {
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.Navbar .image {
  height: 45px;
}
@media only screen and (max-width: 500px) {
  .Navbar .image {
    height: 45px;
  }
}

.Navbar .menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.Navbar .nav-menu {
  background-color:#2d313d;;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  border-radius: 0px 40px 40px 0px;
}

.Navbar .nav-menu.active {
  left: 0;
  transition: 350ms;
}

.Navbar .nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 12px;
  list-style: none;
  height: 60px;
}

.Navbar .nav-text a {
  text-decoration: none;
  color: #dfdede;
  font-family: 'auditionFonto';
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.Navbar .nav-text a:hover {
  background-color: #3b3a3b;
}

.Navbar .nav-menu-items {
  width: 100%;
  padding: 4px;
  overflow-y: scroll;
}

.Navbar .navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.Navbar span {
  margin-left: 16px;
}

/* sidebar toggle */

.Navbar .con,
.Navbar .special-con {
  cursor: pointer;
  display: inline-block;
}

.Navbar .bar {
  display: block;
  height: 5px;
  width: 50px;
  background: #d0cdcd;
  margin: 10px auto;
}

.Navbar .con {
  width: auto;
  margin: 0 auto;
  position: relative;
  right: 0.75rem;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.Navbar .con:hover .bar,
.special-con:hover .bar {
  background-color: #d0cdcd;
}

.Navbar .col {
  position: relative;
  left: 6px;
}

.Navbar .bar {
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.Navbar .con:hover .arrow-top {
  -webkit-transform: rotateZ(45deg) translateY(11px);
  -moz-transform: rotateZ(45deg) translateY(11px);
  -ms-transform: rotateZ(45deg) translateY(11px);
  -o-transform: rotateZ(45deg) translateY(11px);
  transform: rotateZ(45deg) translateY(11px);
  width: 25px;
}

.con:hover .arrow-middle {
  -webkit-transform: translateX(-25px);
  -moz-transform: translateX(-25px);
  -ms-transform: translateX(-25px);
  -o-transform: translateX(-25px);
  transform: translateX(-25px);
}

.con:hover .arrow-bottom {
  -webkit-transform: rotateZ(-45deg) translateY(-11px);
  -moz-transform: rotateZ(-45deg) translateY(-11px);
  -ms-transform: rotateZ(-45deg) translateY(-11px);
  -o-transform: rotateZ(-45deg) translateY(-11px);
  transform: rotateZ(-45deg) translateY(-11px);
  width: 25px;
}

.con:hover .arrow-top-r {
  -webkit-transform: rotateZ(-45deg) translateY(11px);
  -moz-transform: rotateZ(-45deg) translateY(11px);
  -ms-transform: rotateZ(-45deg) translateY(11px);
  -o-transform: rotateZ(-45deg) translateY(11px);
  transform: rotateZ(-45deg) translateY(11px);
  width: 25px;
}

.con:hover .arrow-middle-r {
  -webkit-transform: translateX(25px);
  -moz-transform: translateX(25px);
  -ms-transform: translateX(25px);
  -o-transform: translateX(25px);
  transform: translateX(25px);
}

.con:hover .arrow-bottom-r {
  -webkit-transform: rotateZ(45deg) translateY(-11px);
  -moz-transform: rotateZ(45deg) translateY(-11px);
  -ms-transform: rotateZ(45deg) translateY(-11px);
  -o-transform: rotateZ(45deg) translateY(-11px);
  transform: rotateZ(45deg) translateY(-11px);
  width: 25px;
}

.con:hover .arrow-top-fall {
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.con:hover .arrow-bottom-fall {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}

.special-con {
  margin: 0 auto;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.special-con:hover .arrow-top-fall {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
}

.arrow-bottom-fall,
.arrow-top-fall {
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.special-con:hover .arrow-bottom-fall {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.Navbar .nav-image-name {
  margin-top: 13px;
  color: #a49d9d;
  font-family: formFont;
  font-weight: bold;
  text-align: center;
}
.Navbar .nav-image-main img {
  border-radius: 50%;
  transform: scale(1.1);
  border: 2px groove #3b3a3b;
}

@media (max-width: 900px){
.Navbar .nav-menu {
  background-color:  #2e2f28;;
}
}