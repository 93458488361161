@import url("https://fonts.googleapis.com/css2?family=Fraunces:ital@1&family=Noto+Serif:ital@1&display=swap");
@font-face {
  font-family: formFont;
  src: url("fonts/Stranger\ Things.ttf");
}
.App {
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: url(../images/strangerwebb.png) rgb(0 0 0 / 22%);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color;
}

/* till 842 it was fine */
@media (min-width: 900px) {
  .App {
    background: url(../images/strangerweb1.jpg) rgb(0 0 0 / 22%);
    background-size: cover;
    background-position: center center;
  }
}
.remaining_page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.Loader .image_loader {
  height: 250px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

/* loader */

.loading {
  animation: fadeOut 1000ms forwards;
  /* display: none; */
}

.main_part {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  animation: fadeIn 1000ms forwards;
}

/* fadeOut */
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0.01;
    width: 100%;
    height: 100%;
  }
  100% {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

/* fadeOut */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    display: flex;
  }
  100% {
    opacity: 1;
    display: flex;
  }
}

/* form styles */
.form-group {
  margin-bottom: 1rem;
  background-color: rgb(216, 219, 231); /* Make the background semi-transparent */
  border-radius: 10px;
  padding: 20px;
  
}
.form-control {
  color: #495057 !important;
  background-color: transparent !important;
  border-radius: 0.25rem !important;
  border: transparent !important;
  border-bottom: 2px solid #696862 !important;
}

.form-control:focus {
  color: #495057 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: transparent !important;
  border-bottom: 2px solid #282727 !important;
  box-shadow: 0 0 0 0 transparent !important;
}

/* @media only screen and (max-width: 700px) {
  .form-group {
    margin: 0 20px;
  }
} */

.form-group .form-group:not(.row) {
  margin: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  color:rgb(6, 6, 6);
}

/* change color of slider */
/* input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #8b6d0d;
  height: 20px;
  width: 20px;
  border-radius: 30% !important;
  background: #8b6d0d !important;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-appearance: none;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin: -10px 0 0 0;
  position: relative;
  top: 4px;
}

 */

input[type="range"]::-webkit-slider-runnable-track {
  background: #6c6c6c !important;
  border: none;
  border-radius: 3px;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #272626;
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #121111;
}

/*IE and Edge thumb*/

.form-group {
  display: flex;
  flex-direction: column;
  border: 3px solid #3b3a3a;
}

.form-group .form-group {
  background: transparent;
  border: none;
}
.form-group .row {
  display: flex;
  flex-direction: row;
}

.form-control{
  font-family: formFont;
  color: rgb(6, 6, 6);
  letter-spacing: 1px;
}
.nav-menu-items::-webkit-scrollbar {
  display: none;
}

.form-label {
  /* font-family: "Noto Serif", serif; */
  font-family: formFont;
  letter-spacing: 1px;
  font-size: 1rem;
}
