.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before, .firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 14s;
}
.firefly:nth-child(1)::after {
  animation-duration: 14s, 9180ms;
  animation-delay: 0ms, 2738ms;
}

@keyframes move1 {
  0% {
    transform: translateX(38vw) translateY(8vh) scale(0.28);
  }
  5% {
    transform: translateX(26vw) translateY(41vh) scale(0.89);
  }
  10% {
    transform: translateX(48vw) translateY(29vh) scale(0.95);
  }
  15% {
    transform: translateX(-22vw) translateY(11vh) scale(0.27);
  }
  20% {
    transform: translateX(33vw) translateY(-37vh) scale(0.72);
  }
  25% {
    transform: translateX(10vw) translateY(24vh) scale(0.38);
  }
  30% {
    transform: translateX(45vw) translateY(5vh) scale(0.39);
  }
  35% {
    transform: translateX(48vw) translateY(17vh) scale(0.66);
  }
  40% {
    transform: translateX(5vw) translateY(-37vh) scale(0.79);
  }
  45% {
    transform: translateX(-41vw) translateY(-19vh) scale(0.59);
  }
  50% {
    transform: translateX(6vw) translateY(-40vh) scale(0.96);
  }
  55% {
    transform: translateX(-13vw) translateY(50vh) scale(0.78);
  }
  60% {
    transform: translateX(40vw) translateY(18vh) scale(0.38);
  }
  65% {
    transform: translateX(8vw) translateY(1vh) scale(0.78);
  }
  70% {
    transform: translateX(41vw) translateY(32vh) scale(0.64);
  }
  75% {
    transform: translateX(-10vw) translateY(43vh) scale(0.76);
  }
  80% {
    transform: translateX(38vw) translateY(36vh) scale(0.44);
  }
  85% {
    transform: translateX(-46vw) translateY(-47vh) scale(0.52);
  }
  90% {
    transform: translateX(-3vw) translateY(8vh) scale(0.47);
  }
  95% {
    transform: translateX(-37vw) translateY(-49vh) scale(0.26);
  }
  100% {
    transform: translateX(-20vw) translateY(-23vh) scale(0.78);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 12s;
}
.firefly:nth-child(2)::after {
  animation-duration: 12s, 6635ms;
  animation-delay: 0ms, 1287ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-5vw) translateY(-21vh) scale(0.98);
  }
  3.8461538462% {
    transform: translateX(-39vw) translateY(-21vh) scale(0.45);
  }
  7.6923076923% {
    transform: translateX(18vw) translateY(27vh) scale(0.65);
  }
  11.5384615385% {
    transform: translateX(-49vw) translateY(-12vh) scale(0.74);
  }
  15.3846153846% {
    transform: translateX(47vw) translateY(-13vh) scale(0.34);
  }
  19.2307692308% {
    transform: translateX(-40vw) translateY(38vh) scale(0.86);
  }
  23.0769230769% {
    transform: translateX(-11vw) translateY(-48vh) scale(0.47);
  }
  26.9230769231% {
    transform: translateX(38vw) translateY(-45vh) scale(0.96);
  }
  30.7692307692% {
    transform: translateX(21vw) translateY(-23vh) scale(0.73);
  }
  34.6153846154% {
    transform: translateX(43vw) translateY(-28vh) scale(1);
  }
  38.4615384615% {
    transform: translateX(15vw) translateY(41vh) scale(0.82);
  }
  42.3076923077% {
    transform: translateX(44vw) translateY(33vh) scale(0.28);
  }
  46.1538461538% {
    transform: translateX(-22vw) translateY(-5vh) scale(0.78);
  }
  50% {
    transform: translateX(9vw) translateY(-41vh) scale(0.78);
  }
  53.8461538462% {
    transform: translateX(44vw) translateY(2vh) scale(0.77);
  }
  57.6923076923% {
    transform: translateX(-48vw) translateY(-37vh) scale(0.53);
  }
  61.5384615385% {
    transform: translateX(-35vw) translateY(-39vh) scale(0.98);
  }
  65.3846153846% {
    transform: translateX(12vw) translateY(2vh) scale(0.76);
  }
  69.2307692308% {
    transform: translateX(-21vw) translateY(-13vh) scale(0.72);
  }
  73.0769230769% {
    transform: translateX(-17vw) translateY(-33vh) scale(0.37);
  }
  76.9230769231% {
    transform: translateX(-15vw) translateY(25vh) scale(0.76);
  }
  80.7692307692% {
    transform: translateX(-14vw) translateY(-48vh) scale(0.53);
  }
  84.6153846154% {
    transform: translateX(45vw) translateY(-12vh) scale(0.81);
  }
  88.4615384615% {
    transform: translateX(3vw) translateY(32vh) scale(0.98);
  }
  92.3076923077% {
    transform: translateX(2vw) translateY(-28vh) scale(0.77);
  }
  96.1538461538% {
    transform: translateX(14vw) translateY(41vh) scale(0.84);
  }
  100% {
    transform: translateX(-34vw) translateY(37vh) scale(0.29);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 9s;
}
.firefly:nth-child(3)::after {
  animation-duration: 9s, 8452ms;
  animation-delay: 0ms, 7737ms;
}

@keyframes move3 {
  0% {
    transform: translateX(41vw) translateY(-36vh) scale(0.69);
  }
  3.8461538462% {
    transform: translateX(43vw) translateY(24vh) scale(0.32);
  }
  7.6923076923% {
    transform: translateX(-3vw) translateY(-34vh) scale(0.46);
  }
  11.5384615385% {
    transform: translateX(-27vw) translateY(27vh) scale(0.96);
  }
  15.3846153846% {
    transform: translateX(41vw) translateY(5vh) scale(0.41);
  }
  19.2307692308% {
    transform: translateX(-43vw) translateY(-20vh) scale(0.33);
  }
  23.0769230769% {
    transform: translateX(41vw) translateY(6vh) scale(0.99);
  }
  26.9230769231% {
    transform: translateX(22vw) translateY(49vh) scale(0.48);
  }
  30.7692307692% {
    transform: translateX(-6vw) translateY(-21vh) scale(0.33);
  }
  34.6153846154% {
    transform: translateX(-5vw) translateY(43vh) scale(0.35);
  }
  38.4615384615% {
    transform: translateX(19vw) translateY(2vh) scale(0.97);
  }
  42.3076923077% {
    transform: translateX(-39vw) translateY(25vh) scale(0.52);
  }
  46.1538461538% {
    transform: translateX(37vw) translateY(47vh) scale(0.91);
  }
  50% {
    transform: translateX(50vw) translateY(-4vh) scale(0.32);
  }
  53.8461538462% {
    transform: translateX(29vw) translateY(21vh) scale(0.96);
  }
  57.6923076923% {
    transform: translateX(20vw) translateY(6vh) scale(0.37);
  }
  61.5384615385% {
    transform: translateX(-22vw) translateY(15vh) scale(0.66);
  }
  65.3846153846% {
    transform: translateX(16vw) translateY(-34vh) scale(0.74);
  }
  69.2307692308% {
    transform: translateX(-17vw) translateY(-7vh) scale(0.71);
  }
  73.0769230769% {
    transform: translateX(23vw) translateY(38vh) scale(0.79);
  }
  76.9230769231% {
    transform: translateX(-22vw) translateY(9vh) scale(0.92);
  }
  80.7692307692% {
    transform: translateX(1vw) translateY(-15vh) scale(0.95);
  }
  84.6153846154% {
    transform: translateX(24vw) translateY(6vh) scale(0.54);
  }
  88.4615384615% {
    transform: translateX(33vw) translateY(49vh) scale(0.33);
  }
  92.3076923077% {
    transform: translateX(18vw) translateY(-38vh) scale(0.82);
  }
  96.1538461538% {
    transform: translateX(-44vw) translateY(-40vh) scale(0.87);
  }
  100% {
    transform: translateX(39vw) translateY(-34vh) scale(0.75);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 14s;
}
.firefly:nth-child(4)::after {
  animation-duration: 14s, 8992ms;
  animation-delay: 0ms, 2178ms;
}

@keyframes move4 {
  0% {
    transform: translateX(-44vw) translateY(-37vh) scale(0.74);
  }
  4.5454545455% {
    transform: translateX(30vw) translateY(-26vh) scale(0.74);
  }
  9.0909090909% {
    transform: translateX(20vw) translateY(-40vh) scale(0.41);
  }
  13.6363636364% {
    transform: translateX(48vw) translateY(-39vh) scale(0.47);
  }
  18.1818181818% {
    transform: translateX(30vw) translateY(48vh) scale(0.74);
  }
  22.7272727273% {
    transform: translateX(-17vw) translateY(3vh) scale(0.85);
  }
  27.2727272727% {
    transform: translateX(8vw) translateY(-45vh) scale(0.98);
  }
  31.8181818182% {
    transform: translateX(-6vw) translateY(-22vh) scale(0.78);
  }
  36.3636363636% {
    transform: translateX(46vw) translateY(-18vh) scale(0.8);
  }
  40.9090909091% {
    transform: translateX(27vw) translateY(46vh) scale(0.46);
  }
  45.4545454545% {
    transform: translateX(-30vw) translateY(39vh) scale(0.66);
  }
  50% {
    transform: translateX(-25vw) translateY(12vh) scale(1);
  }
  54.5454545455% {
    transform: translateX(-31vw) translateY(36vh) scale(0.49);
  }
  59.0909090909% {
    transform: translateX(-15vw) translateY(18vh) scale(0.35);
  }
  63.6363636364% {
    transform: translateX(-24vw) translateY(-31vh) scale(0.51);
  }
  68.1818181818% {
    transform: translateX(26vw) translateY(9vh) scale(0.97);
  }
  72.7272727273% {
    transform: translateX(-33vw) translateY(-31vh) scale(1);
  }
  77.2727272727% {
    transform: translateX(49vw) translateY(5vh) scale(0.49);
  }
  81.8181818182% {
    transform: translateX(-49vw) translateY(1vh) scale(0.82);
  }
  86.3636363636% {
    transform: translateX(-47vw) translateY(-4vh) scale(0.29);
  }
  90.9090909091% {
    transform: translateX(31vw) translateY(-49vh) scale(0.56);
  }
  95.4545454545% {
    transform: translateX(-45vw) translateY(21vh) scale(0.74);
  }
  100% {
    transform: translateX(3vw) translateY(3vh) scale(0.27);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 16s;
}
.firefly:nth-child(5)::after {
  animation-duration: 16s, 9497ms;
  animation-delay: 0ms, 1361ms;
}

@keyframes move5 {
  0% {
    transform: translateX(-31vw) translateY(-14vh) scale(0.94);
  }
  3.5714285714% {
    transform: translateX(50vw) translateY(-2vh) scale(0.74);
  }
  7.1428571429% {
    transform: translateX(40vw) translateY(10vh) scale(0.36);
  }
  10.7142857143% {
    transform: translateX(42vw) translateY(-13vh) scale(0.74);
  }
  14.2857142857% {
    transform: translateX(6vw) translateY(-13vh) scale(0.43);
  }
  17.8571428571% {
    transform: translateX(41vw) translateY(22vh) scale(0.71);
  }
  21.4285714286% {
    transform: translateX(-7vw) translateY(36vh) scale(0.49);
  }
  25% {
    transform: translateX(5vw) translateY(-8vh) scale(0.75);
  }
  28.5714285714% {
    transform: translateX(3vw) translateY(-17vh) scale(0.32);
  }
  32.1428571429% {
    transform: translateX(40vw) translateY(-37vh) scale(0.51);
  }
  35.7142857143% {
    transform: translateX(-27vw) translateY(37vh) scale(0.81);
  }
  39.2857142857% {
    transform: translateX(21vw) translateY(-4vh) scale(0.83);
  }
  42.8571428571% {
    transform: translateX(27vw) translateY(31vh) scale(0.36);
  }
  46.4285714286% {
    transform: translateX(-6vw) translateY(31vh) scale(0.56);
  }
  50% {
    transform: translateX(-24vw) translateY(-38vh) scale(0.79);
  }
  53.5714285714% {
    transform: translateX(9vw) translateY(-4vh) scale(0.93);
  }
  57.1428571429% {
    transform: translateX(-44vw) translateY(50vh) scale(0.51);
  }
  60.7142857143% {
    transform: translateX(43vw) translateY(-33vh) scale(0.38);
  }
  64.2857142857% {
    transform: translateX(45vw) translateY(-35vh) scale(1);
  }
  67.8571428571% {
    transform: translateX(-39vw) translateY(-26vh) scale(0.84);
  }
  71.4285714286% {
    transform: translateX(-20vw) translateY(-28vh) scale(0.9);
  }
  75% {
    transform: translateX(4vw) translateY(-16vh) scale(0.62);
  }
  78.5714285714% {
    transform: translateX(32vw) translateY(30vh) scale(0.33);
  }
  82.1428571429% {
    transform: translateX(-40vw) translateY(17vh) scale(0.5);
  }
  85.7142857143% {
    transform: translateX(28vw) translateY(-40vh) scale(0.54);
  }
  89.2857142857% {
    transform: translateX(-7vw) translateY(-32vh) scale(0.37);
  }
  92.8571428571% {
    transform: translateX(-21vw) translateY(-11vh) scale(0.9);
  }
  96.4285714286% {
    transform: translateX(25vw) translateY(40vh) scale(0.95);
  }
  100% {
    transform: translateX(12vw) translateY(25vh) scale(0.43);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 15s;
}
.firefly:nth-child(6)::after {
  animation-duration: 15s, 6362ms;
  animation-delay: 0ms, 3401ms;
}

@keyframes move6 {
  0% {
    transform: translateX(-27vw) translateY(-16vh) scale(0.47);
  }
  3.7037037037% {
    transform: translateX(3vw) translateY(-43vh) scale(0.75);
  }
  7.4074074074% {
    transform: translateX(-2vw) translateY(-26vh) scale(0.52);
  }
  11.1111111111% {
    transform: translateX(50vw) translateY(-11vh) scale(0.87);
  }
  14.8148148148% {
    transform: translateX(-46vw) translateY(-43vh) scale(0.39);
  }
  18.5185185185% {
    transform: translateX(-23vw) translateY(13vh) scale(0.78);
  }
  22.2222222222% {
    transform: translateX(-43vw) translateY(-5vh) scale(0.65);
  }
  25.9259259259% {
    transform: translateX(8vw) translateY(-30vh) scale(0.78);
  }
  29.6296296296% {
    transform: translateX(18vw) translateY(12vh) scale(0.35);
  }
  33.3333333333% {
    transform: translateX(-26vw) translateY(18vh) scale(0.96);
  }
  37.037037037% {
    transform: translateX(25vw) translateY(-14vh) scale(0.54);
  }
  40.7407407407% {
    transform: translateX(-35vw) translateY(2vh) scale(0.73);
  }
  44.4444444444% {
    transform: translateX(-9vw) translateY(34vh) scale(0.78);
  }
  48.1481481481% {
    transform: translateX(25vw) translateY(-47vh) scale(0.55);
  }
  51.8518518519% {
    transform: translateX(-40vw) translateY(18vh) scale(0.44);
  }
  55.5555555556% {
    transform: translateX(26vw) translateY(9vh) scale(0.56);
  }
  59.2592592593% {
    transform: translateX(-49vw) translateY(-31vh) scale(0.58);
  }
  62.962962963% {
    transform: translateX(26vw) translateY(45vh) scale(0.43);
  }
  66.6666666667% {
    transform: translateX(-44vw) translateY(-6vh) scale(0.81);
  }
  70.3703703704% {
    transform: translateX(-47vw) translateY(-11vh) scale(0.59);
  }
  74.0740740741% {
    transform: translateX(1vw) translateY(15vh) scale(0.3);
  }
  77.7777777778% {
    transform: translateX(-15vw) translateY(49vh) scale(0.67);
  }
  81.4814814815% {
    transform: translateX(-13vw) translateY(-22vh) scale(0.56);
  }
  85.1851851852% {
    transform: translateX(-3vw) translateY(-49vh) scale(0.92);
  }
  88.8888888889% {
    transform: translateX(14vw) translateY(27vh) scale(0.31);
  }
  92.5925925926% {
    transform: translateX(-45vw) translateY(-6vh) scale(0.66);
  }
  96.2962962963% {
    transform: translateX(23vw) translateY(-33vh) scale(0.32);
  }
  100% {
    transform: translateX(20vw) translateY(12vh) scale(0.4);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 16s;
}
.firefly:nth-child(7)::after {
  animation-duration: 16s, 9175ms;
  animation-delay: 0ms, 3512ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-41vw) translateY(-39vh) scale(0.94);
  }
  5.2631578947% {
    transform: translateX(2vw) translateY(-12vh) scale(0.45);
  }
  10.5263157895% {
    transform: translateX(-28vw) translateY(49vh) scale(0.29);
  }
  15.7894736842% {
    transform: translateX(34vw) translateY(38vh) scale(0.58);
  }
  21.0526315789% {
    transform: translateX(5vw) translateY(33vh) scale(0.26);
  }
  26.3157894737% {
    transform: translateX(-17vw) translateY(30vh) scale(0.32);
  }
  31.5789473684% {
    transform: translateX(-22vw) translateY(10vh) scale(0.41);
  }
  36.8421052632% {
    transform: translateX(17vw) translateY(-34vh) scale(0.55);
  }
  42.1052631579% {
    transform: translateX(-8vw) translateY(-46vh) scale(0.77);
  }
  47.3684210526% {
    transform: translateX(13vw) translateY(-46vh) scale(0.73);
  }
  52.6315789474% {
    transform: translateX(-18vw) translateY(-41vh) scale(0.44);
  }
  57.8947368421% {
    transform: translateX(-30vw) translateY(0vh) scale(1);
  }
  63.1578947368% {
    transform: translateX(20vw) translateY(-46vh) scale(0.96);
  }
  68.4210526316% {
    transform: translateX(5vw) translateY(19vh) scale(0.36);
  }
  73.6842105263% {
    transform: translateX(-2vw) translateY(15vh) scale(0.45);
  }
  78.9473684211% {
    transform: translateX(20vw) translateY(-28vh) scale(0.39);
  }
  84.2105263158% {
    transform: translateX(-5vw) translateY(-28vh) scale(0.76);
  }
  89.4736842105% {
    transform: translateX(-33vw) translateY(-6vh) scale(0.79);
  }
  94.7368421053% {
    transform: translateX(37vw) translateY(13vh) scale(0.34);
  }
  100% {
    transform: translateX(-41vw) translateY(-3vh) scale(0.57);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 14s;
}
.firefly:nth-child(8)::after {
  animation-duration: 14s, 6798ms;
  animation-delay: 0ms, 7303ms;
}

@keyframes move8 {
  0% {
    transform: translateX(24vw) translateY(-12vh) scale(0.65);
  }
  5.5555555556% {
    transform: translateX(-19vw) translateY(-4vh) scale(0.33);
  }
  11.1111111111% {
    transform: translateX(-49vw) translateY(22vh) scale(0.72);
  }
  16.6666666667% {
    transform: translateX(5vw) translateY(23vh) scale(0.91);
  }
  22.2222222222% {
    transform: translateX(-27vw) translateY(45vh) scale(0.7);
  }
  27.7777777778% {
    transform: translateX(24vw) translateY(47vh) scale(0.68);
  }
  33.3333333333% {
    transform: translateX(45vw) translateY(7vh) scale(1);
  }
  38.8888888889% {
    transform: translateX(-28vw) translateY(-9vh) scale(0.51);
  }
  44.4444444444% {
    transform: translateX(-28vw) translateY(20vh) scale(0.26);
  }
  50% {
    transform: translateX(-15vw) translateY(2vh) scale(0.52);
  }
  55.5555555556% {
    transform: translateX(-44vw) translateY(42vh) scale(0.45);
  }
  61.1111111111% {
    transform: translateX(43vw) translateY(-44vh) scale(0.48);
  }
  66.6666666667% {
    transform: translateX(-38vw) translateY(35vh) scale(0.44);
  }
  72.2222222222% {
    transform: translateX(27vw) translateY(-46vh) scale(0.39);
  }
  77.7777777778% {
    transform: translateX(35vw) translateY(-14vh) scale(0.75);
  }
  83.3333333333% {
    transform: translateX(-8vw) translateY(-14vh) scale(0.98);
  }
  88.8888888889% {
    transform: translateX(-30vw) translateY(-3vh) scale(0.86);
  }
  94.4444444444% {
    transform: translateX(9vw) translateY(-43vh) scale(0.91);
  }
  100% {
    transform: translateX(30vw) translateY(38vh) scale(0.4);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 11s;
}
.firefly:nth-child(9)::after {
  animation-duration: 11s, 7211ms;
  animation-delay: 0ms, 1225ms;
}

@keyframes move9 {
  0% {
    transform: translateX(30vw) translateY(31vh) scale(0.29);
  }
  4.7619047619% {
    transform: translateX(-7vw) translateY(7vh) scale(0.35);
  }
  9.5238095238% {
    transform: translateX(-44vw) translateY(-19vh) scale(0.39);
  }
  14.2857142857% {
    transform: translateX(-37vw) translateY(-22vh) scale(0.26);
  }
  19.0476190476% {
    transform: translateX(43vw) translateY(49vh) scale(0.99);
  }
  23.8095238095% {
    transform: translateX(-1vw) translateY(-38vh) scale(0.32);
  }
  28.5714285714% {
    transform: translateX(-22vw) translateY(20vh) scale(0.72);
  }
  33.3333333333% {
    transform: translateX(-39vw) translateY(-44vh) scale(0.74);
  }
  38.0952380952% {
    transform: translateX(6vw) translateY(-49vh) scale(0.93);
  }
  42.8571428571% {
    transform: translateX(34vw) translateY(-44vh) scale(0.6);
  }
  47.619047619% {
    transform: translateX(-10vw) translateY(22vh) scale(0.9);
  }
  52.380952381% {
    transform: translateX(-4vw) translateY(-1vh) scale(0.78);
  }
  57.1428571429% {
    transform: translateX(-11vw) translateY(-9vh) scale(0.48);
  }
  61.9047619048% {
    transform: translateX(24vw) translateY(42vh) scale(1);
  }
  66.6666666667% {
    transform: translateX(-12vw) translateY(-36vh) scale(0.73);
  }
  71.4285714286% {
    transform: translateX(3vw) translateY(-41vh) scale(0.61);
  }
  76.1904761905% {
    transform: translateX(39vw) translateY(48vh) scale(0.29);
  }
  80.9523809524% {
    transform: translateX(38vw) translateY(-38vh) scale(0.95);
  }
  85.7142857143% {
    transform: translateX(18vw) translateY(-9vh) scale(0.77);
  }
  90.4761904762% {
    transform: translateX(-25vw) translateY(-23vh) scale(0.54);
  }
  95.2380952381% {
    transform: translateX(9vw) translateY(17vh) scale(0.43);
  }
  100% {
    transform: translateX(44vw) translateY(36vh) scale(0.74);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 13s;
}
.firefly:nth-child(10)::after {
  animation-duration: 13s, 8170ms;
  animation-delay: 0ms, 5905ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-11vw) translateY(25vh) scale(0.55);
  }
  3.5714285714% {
    transform: translateX(42vw) translateY(40vh) scale(0.44);
  }
  7.1428571429% {
    transform: translateX(18vw) translateY(35vh) scale(0.34);
  }
  10.7142857143% {
    transform: translateX(-11vw) translateY(-20vh) scale(0.51);
  }
  14.2857142857% {
    transform: translateX(25vw) translateY(23vh) scale(0.9);
  }
  17.8571428571% {
    transform: translateX(-39vw) translateY(0vh) scale(0.8);
  }
  21.4285714286% {
    transform: translateX(-39vw) translateY(-22vh) scale(0.89);
  }
  25% {
    transform: translateX(2vw) translateY(-26vh) scale(0.26);
  }
  28.5714285714% {
    transform: translateX(29vw) translateY(-37vh) scale(0.9);
  }
  32.1428571429% {
    transform: translateX(-24vw) translateY(-48vh) scale(0.46);
  }
  35.7142857143% {
    transform: translateX(37vw) translateY(-39vh) scale(0.29);
  }
  39.2857142857% {
    transform: translateX(-6vw) translateY(50vh) scale(0.42);
  }
  42.8571428571% {
    transform: translateX(32vw) translateY(17vh) scale(0.95);
  }
  46.4285714286% {
    transform: translateX(50vw) translateY(-8vh) scale(0.78);
  }
  50% {
    transform: translateX(40vw) translateY(-8vh) scale(0.72);
  }
  53.5714285714% {
    transform: translateX(18vw) translateY(-42vh) scale(0.54);
  }
  57.1428571429% {
    transform: translateX(-41vw) translateY(39vh) scale(0.92);
  }
  60.7142857143% {
    transform: translateX(34vw) translateY(3vh) scale(0.88);
  }
  64.2857142857% {
    transform: translateX(-34vw) translateY(34vh) scale(0.38);
  }
  67.8571428571% {
    transform: translateX(21vw) translateY(43vh) scale(0.36);
  }
  71.4285714286% {
    transform: translateX(-37vw) translateY(-12vh) scale(0.54);
  }
  75% {
    transform: translateX(-30vw) translateY(-33vh) scale(0.66);
  }
  78.5714285714% {
    transform: translateX(-20vw) translateY(42vh) scale(0.41);
  }
  82.1428571429% {
    transform: translateX(11vw) translateY(-33vh) scale(0.44);
  }
  85.7142857143% {
    transform: translateX(-21vw) translateY(22vh) scale(0.38);
  }
  89.2857142857% {
    transform: translateX(-15vw) translateY(-25vh) scale(0.63);
  }
  92.8571428571% {
    transform: translateX(9vw) translateY(-45vh) scale(0.28);
  }
  96.4285714286% {
    transform: translateX(5vw) translateY(-25vh) scale(0.34);
  }
  100% {
    transform: translateX(-42vw) translateY(7vh) scale(0.97);
  }
}
.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 12s;
}
.firefly:nth-child(11)::after {
  animation-duration: 12s, 9433ms;
  animation-delay: 0ms, 1513ms;
}

@keyframes move11 {
  0% {
    transform: translateX(26vw) translateY(-47vh) scale(0.37);
  }
  4% {
    transform: translateX(5vw) translateY(-20vh) scale(0.81);
  }
  8% {
    transform: translateX(-7vw) translateY(9vh) scale(1);
  }
  12% {
    transform: translateX(37vw) translateY(30vh) scale(0.72);
  }
  16% {
    transform: translateX(15vw) translateY(22vh) scale(0.31);
  }
  20% {
    transform: translateX(-21vw) translateY(39vh) scale(0.44);
  }
  24% {
    transform: translateX(-46vw) translateY(-23vh) scale(0.81);
  }
  28% {
    transform: translateX(-22vw) translateY(47vh) scale(0.99);
  }
  32% {
    transform: translateX(-15vw) translateY(39vh) scale(0.57);
  }
  36% {
    transform: translateX(-26vw) translateY(-29vh) scale(0.45);
  }
  40% {
    transform: translateX(37vw) translateY(-20vh) scale(0.67);
  }
  44% {
    transform: translateX(15vw) translateY(5vh) scale(0.41);
  }
  48% {
    transform: translateX(46vw) translateY(-23vh) scale(0.43);
  }
  52% {
    transform: translateX(-27vw) translateY(-49vh) scale(0.61);
  }
  56% {
    transform: translateX(-13vw) translateY(-34vh) scale(0.75);
  }
  60% {
    transform: translateX(42vw) translateY(-32vh) scale(0.62);
  }
  64% {
    transform: translateX(2vw) translateY(36vh) scale(0.94);
  }
  68% {
    transform: translateX(7vw) translateY(22vh) scale(0.9);
  }
  72% {
    transform: translateX(35vw) translateY(27vh) scale(0.58);
  }
  76% {
    transform: translateX(39vw) translateY(42vh) scale(0.79);
  }
  80% {
    transform: translateX(-43vw) translateY(22vh) scale(0.26);
  }
  84% {
    transform: translateX(-27vw) translateY(-6vh) scale(0.54);
  }
  88% {
    transform: translateX(39vw) translateY(21vh) scale(0.66);
  }
  92% {
    transform: translateX(-16vw) translateY(-15vh) scale(0.79);
  }
  96% {
    transform: translateX(43vw) translateY(6vh) scale(0.87);
  }
  100% {
    transform: translateX(25vw) translateY(-6vh) scale(0.43);
  }
}
.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 9s;
}
.firefly:nth-child(12)::after {
  animation-duration: 9s, 9612ms;
  animation-delay: 0ms, 4113ms;
}

@keyframes move12 {
  0% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.4);
  }
  3.7037037037% {
    transform: translateX(32vw) translateY(32vh) scale(0.33);
  }
  7.4074074074% {
    transform: translateX(-22vw) translateY(-45vh) scale(0.54);
  }
  11.1111111111% {
    transform: translateX(45vw) translateY(37vh) scale(0.45);
  }
  14.8148148148% {
    transform: translateX(-7vw) translateY(30vh) scale(0.67);
  }
  18.5185185185% {
    transform: translateX(-30vw) translateY(-36vh) scale(0.99);
  }
  22.2222222222% {
    transform: translateX(-7vw) translateY(-10vh) scale(0.7);
  }
  25.9259259259% {
    transform: translateX(39vw) translateY(31vh) scale(0.86);
  }
  29.6296296296% {
    transform: translateX(-30vw) translateY(-1vh) scale(0.27);
  }
  33.3333333333% {
    transform: translateX(4vw) translateY(-31vh) scale(0.55);
  }
  37.037037037% {
    transform: translateX(-16vw) translateY(45vh) scale(0.43);
  }
  40.7407407407% {
    transform: translateX(-29vw) translateY(5vh) scale(0.42);
  }
  44.4444444444% {
    transform: translateX(-15vw) translateY(-10vh) scale(0.81);
  }
  48.1481481481% {
    transform: translateX(6vw) translateY(-7vh) scale(0.99);
  }
  51.8518518519% {
    transform: translateX(-24vw) translateY(-2vh) scale(0.69);
  }
  55.5555555556% {
    transform: translateX(15vw) translateY(3vh) scale(0.32);
  }
  59.2592592593% {
    transform: translateX(50vw) translateY(-1vh) scale(0.75);
  }
  62.962962963% {
    transform: translateX(-17vw) translateY(43vh) scale(0.5);
  }
  66.6666666667% {
    transform: translateX(35vw) translateY(27vh) scale(0.6);
  }
  70.3703703704% {
    transform: translateX(35vw) translateY(-48vh) scale(0.74);
  }
  74.0740740741% {
    transform: translateX(9vw) translateY(48vh) scale(0.75);
  }
  77.7777777778% {
    transform: translateX(14vw) translateY(-37vh) scale(0.66);
  }
  81.4814814815% {
    transform: translateX(-38vw) translateY(-5vh) scale(0.34);
  }
  85.1851851852% {
    transform: translateX(-18vw) translateY(-27vh) scale(0.8);
  }
  88.8888888889% {
    transform: translateX(50vw) translateY(26vh) scale(0.96);
  }
  92.5925925926% {
    transform: translateX(-40vw) translateY(-8vh) scale(0.85);
  }
  96.2962962963% {
    transform: translateX(26vw) translateY(-4vh) scale(0.32);
  }
  100% {
    transform: translateX(2vw) translateY(-9vh) scale(0.94);
  }
}
.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 16s;
}
.firefly:nth-child(13)::after {
  animation-duration: 16s, 9421ms;
  animation-delay: 0ms, 4515ms;
}

@keyframes move13 {
  0% {
    transform: translateX(2vw) translateY(-15vh) scale(0.59);
  }
  5.5555555556% {
    transform: translateX(50vw) translateY(-2vh) scale(0.94);
  }
  11.1111111111% {
    transform: translateX(22vw) translateY(-48vh) scale(0.3);
  }
  16.6666666667% {
    transform: translateX(22vw) translateY(38vh) scale(0.89);
  }
  22.2222222222% {
    transform: translateX(26vw) translateY(-18vh) scale(0.41);
  }
  27.7777777778% {
    transform: translateX(-3vw) translateY(8vh) scale(0.56);
  }
  33.3333333333% {
    transform: translateX(-43vw) translateY(-5vh) scale(0.97);
  }
  38.8888888889% {
    transform: translateX(24vw) translateY(-9vh) scale(0.51);
  }
  44.4444444444% {
    transform: translateX(3vw) translateY(46vh) scale(0.64);
  }
  50% {
    transform: translateX(6vw) translateY(-10vh) scale(0.45);
  }
  55.5555555556% {
    transform: translateX(37vw) translateY(-4vh) scale(0.4);
  }
  61.1111111111% {
    transform: translateX(-16vw) translateY(-21vh) scale(0.49);
  }
  66.6666666667% {
    transform: translateX(20vw) translateY(-23vh) scale(0.71);
  }
  72.2222222222% {
    transform: translateX(0vw) translateY(13vh) scale(0.5);
  }
  77.7777777778% {
    transform: translateX(14vw) translateY(16vh) scale(0.78);
  }
  83.3333333333% {
    transform: translateX(11vw) translateY(-13vh) scale(0.98);
  }
  88.8888888889% {
    transform: translateX(-43vw) translateY(-12vh) scale(0.41);
  }
  94.4444444444% {
    transform: translateX(-7vw) translateY(22vh) scale(0.37);
  }
  100% {
    transform: translateX(-27vw) translateY(-15vh) scale(0.76);
  }
}
.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 18s;
}
.firefly:nth-child(14)::after {
  animation-duration: 18s, 5314ms;
  animation-delay: 0ms, 4602ms;
}

@keyframes move14 {
  0% {
    transform: translateX(34vw) translateY(-40vh) scale(0.57);
  }
  3.7037037037% {
    transform: translateX(-10vw) translateY(-31vh) scale(0.32);
  }
  7.4074074074% {
    transform: translateX(37vw) translateY(12vh) scale(0.78);
  }
  11.1111111111% {
    transform: translateX(-18vw) translateY(-40vh) scale(0.49);
  }
  14.8148148148% {
    transform: translateX(-7vw) translateY(-27vh) scale(0.43);
  }
  18.5185185185% {
    transform: translateX(41vw) translateY(-10vh) scale(0.55);
  }
  22.2222222222% {
    transform: translateX(47vw) translateY(5vh) scale(0.26);
  }
  25.9259259259% {
    transform: translateX(13vw) translateY(-18vh) scale(0.92);
  }
  29.6296296296% {
    transform: translateX(-42vw) translateY(-29vh) scale(0.63);
  }
  33.3333333333% {
    transform: translateX(-49vw) translateY(-19vh) scale(0.34);
  }
  37.037037037% {
    transform: translateX(26vw) translateY(-15vh) scale(0.78);
  }
  40.7407407407% {
    transform: translateX(2vw) translateY(6vh) scale(0.35);
  }
  44.4444444444% {
    transform: translateX(-18vw) translateY(-6vh) scale(0.45);
  }
  48.1481481481% {
    transform: translateX(7vw) translateY(-3vh) scale(0.48);
  }
  51.8518518519% {
    transform: translateX(26vw) translateY(-15vh) scale(0.55);
  }
  55.5555555556% {
    transform: translateX(-28vw) translateY(-29vh) scale(0.94);
  }
  59.2592592593% {
    transform: translateX(-44vw) translateY(-2vh) scale(0.63);
  }
  62.962962963% {
    transform: translateX(38vw) translateY(-9vh) scale(0.26);
  }
  66.6666666667% {
    transform: translateX(16vw) translateY(5vh) scale(0.81);
  }
  70.3703703704% {
    transform: translateX(-49vw) translateY(15vh) scale(0.53);
  }
  74.0740740741% {
    transform: translateX(21vw) translateY(-16vh) scale(0.46);
  }
  77.7777777778% {
    transform: translateX(9vw) translateY(-9vh) scale(0.79);
  }
  81.4814814815% {
    transform: translateX(-4vw) translateY(-31vh) scale(0.97);
  }
  85.1851851852% {
    transform: translateX(24vw) translateY(43vh) scale(0.99);
  }
  88.8888888889% {
    transform: translateX(-14vw) translateY(-25vh) scale(0.33);
  }
  92.5925925926% {
    transform: translateX(-9vw) translateY(-47vh) scale(0.43);
  }
  96.2962962963% {
    transform: translateX(-20vw) translateY(-36vh) scale(0.37);
  }
  100% {
    transform: translateX(23vw) translateY(-3vh) scale(0.37);
  }
}
.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 10s;
}
.firefly:nth-child(15)::after {
  animation-duration: 10s, 7671ms;
  animation-delay: 0ms, 1809ms;
}

@keyframes move15 {
  0% {
    transform: translateX(44vw) translateY(47vh) scale(0.54);
  }
  4.5454545455% {
    transform: translateX(-7vw) translateY(-49vh) scale(0.58);
  }
  9.0909090909% {
    transform: translateX(14vw) translateY(47vh) scale(0.98);
  }
  13.6363636364% {
    transform: translateX(-40vw) translateY(40vh) scale(0.36);
  }
  18.1818181818% {
    transform: translateX(9vw) translateY(-43vh) scale(0.42);
  }
  22.7272727273% {
    transform: translateX(-43vw) translateY(40vh) scale(0.64);
  }
  27.2727272727% {
    transform: translateX(-34vw) translateY(-24vh) scale(0.3);
  }
  31.8181818182% {
    transform: translateX(0vw) translateY(-42vh) scale(0.94);
  }
  36.3636363636% {
    transform: translateX(-41vw) translateY(-45vh) scale(0.69);
  }
  40.9090909091% {
    transform: translateX(-43vw) translateY(41vh) scale(0.33);
  }
  45.4545454545% {
    transform: translateX(42vw) translateY(-25vh) scale(0.39);
  }
  50% {
    transform: translateX(-19vw) translateY(24vh) scale(0.26);
  }
  54.5454545455% {
    transform: translateX(-16vw) translateY(28vh) scale(0.43);
  }
  59.0909090909% {
    transform: translateX(-41vw) translateY(-28vh) scale(0.49);
  }
  63.6363636364% {
    transform: translateX(-19vw) translateY(-14vh) scale(0.84);
  }
  68.1818181818% {
    transform: translateX(14vw) translateY(-26vh) scale(0.83);
  }
  72.7272727273% {
    transform: translateX(1vw) translateY(13vh) scale(0.26);
  }
  77.2727272727% {
    transform: translateX(-21vw) translateY(16vh) scale(0.95);
  }
  81.8181818182% {
    transform: translateX(34vw) translateY(34vh) scale(0.8);
  }
  86.3636363636% {
    transform: translateX(47vw) translateY(46vh) scale(0.81);
  }
  90.9090909091% {
    transform: translateX(28vw) translateY(-27vh) scale(0.93);
  }
  95.4545454545% {
    transform: translateX(-23vw) translateY(-15vh) scale(0.37);
  }
  100% {
    transform: translateX(34vw) translateY(-5vh) scale(0.85);
  }
}
.firefly:nth-child(16) {
  animation-name: move16;
}
.firefly:nth-child(16)::before {
  animation-duration: 11s;
}
.firefly:nth-child(16)::after {
  animation-duration: 11s, 7828ms;
  animation-delay: 0ms, 5275ms;
}

@keyframes move16 {
  0% {
    transform: translateX(16vw) translateY(31vh) scale(0.77);
  }
  5% {
    transform: translateX(14vw) translateY(49vh) scale(1);
  }
  10% {
    transform: translateX(6vw) translateY(23vh) scale(0.97);
  }
  15% {
    transform: translateX(46vw) translateY(13vh) scale(0.97);
  }
  20% {
    transform: translateX(31vw) translateY(2vh) scale(0.73);
  }
  25% {
    transform: translateX(-23vw) translateY(49vh) scale(0.3);
  }
  30% {
    transform: translateX(-32vw) translateY(6vh) scale(0.5);
  }
  35% {
    transform: translateX(-44vw) translateY(-25vh) scale(0.81);
  }
  40% {
    transform: translateX(22vw) translateY(-30vh) scale(0.89);
  }
  45% {
    transform: translateX(-49vw) translateY(38vh) scale(0.43);
  }
  50% {
    transform: translateX(23vw) translateY(-10vh) scale(0.54);
  }
  55% {
    transform: translateX(46vw) translateY(48vh) scale(0.52);
  }
  60% {
    transform: translateX(-37vw) translateY(25vh) scale(0.65);
  }
  65% {
    transform: translateX(29vw) translateY(-32vh) scale(0.95);
  }
  70% {
    transform: translateX(-19vw) translateY(-39vh) scale(0.42);
  }
  75% {
    transform: translateX(50vw) translateY(4vh) scale(0.64);
  }
  80% {
    transform: translateX(37vw) translateY(11vh) scale(0.49);
  }
  85% {
    transform: translateX(-14vw) translateY(16vh) scale(0.83);
  }
  90% {
    transform: translateX(27vw) translateY(-10vh) scale(0.27);
  }
  95% {
    transform: translateX(17vw) translateY(-12vh) scale(0.83);
  }
  100% {
    transform: translateX(39vw) translateY(2vh) scale(0.41);
  }
}
.firefly:nth-child(17) {
  animation-name: move17;
}
.firefly:nth-child(17)::before {
  animation-duration: 11s;
}
.firefly:nth-child(17)::after {
  animation-duration: 11s, 5190ms;
  animation-delay: 0ms, 4373ms;
}

@keyframes move17 {
  0% {
    transform: translateX(8vw) translateY(-11vh) scale(0.69);
  }
  5.2631578947% {
    transform: translateX(-5vw) translateY(-14vh) scale(0.92);
  }
  10.5263157895% {
    transform: translateX(43vw) translateY(17vh) scale(0.59);
  }
  15.7894736842% {
    transform: translateX(23vw) translateY(-26vh) scale(0.38);
  }
  21.0526315789% {
    transform: translateX(10vw) translateY(6vh) scale(0.4);
  }
  26.3157894737% {
    transform: translateX(17vw) translateY(44vh) scale(0.39);
  }
  31.5789473684% {
    transform: translateX(-25vw) translateY(36vh) scale(0.57);
  }
  36.8421052632% {
    transform: translateX(33vw) translateY(29vh) scale(0.86);
  }
  42.1052631579% {
    transform: translateX(-24vw) translateY(-26vh) scale(0.5);
  }
  47.3684210526% {
    transform: translateX(9vw) translateY(-39vh) scale(0.82);
  }
  52.6315789474% {
    transform: translateX(13vw) translateY(-3vh) scale(0.77);
  }
  57.8947368421% {
    transform: translateX(23vw) translateY(-13vh) scale(0.83);
  }
  63.1578947368% {
    transform: translateX(48vw) translateY(-15vh) scale(0.57);
  }
  68.4210526316% {
    transform: translateX(39vw) translateY(-47vh) scale(0.8);
  }
  73.6842105263% {
    transform: translateX(42vw) translateY(-27vh) scale(0.73);
  }
  78.9473684211% {
    transform: translateX(0vw) translateY(-2vh) scale(0.44);
  }
  84.2105263158% {
    transform: translateX(50vw) translateY(-6vh) scale(0.84);
  }
  89.4736842105% {
    transform: translateX(49vw) translateY(23vh) scale(0.62);
  }
  94.7368421053% {
    transform: translateX(-7vw) translateY(43vh) scale(0.58);
  }
  100% {
    transform: translateX(26vw) translateY(32vh) scale(0.8);
  }
}
.firefly:nth-child(18) {
  animation-name: move18;
}
.firefly:nth-child(18)::before {
  animation-duration: 13s;
}
.firefly:nth-child(18)::after {
  animation-duration: 13s, 10528ms;
  animation-delay: 0ms, 855ms;
}

@keyframes move18 {
  0% {
    transform: translateX(-12vw) translateY(-19vh) scale(0.67);
  }
  3.8461538462% {
    transform: translateX(40vw) translateY(2vh) scale(0.73);
  }
  7.6923076923% {
    transform: translateX(-35vw) translateY(7vh) scale(0.61);
  }
  11.5384615385% {
    transform: translateX(13vw) translateY(38vh) scale(0.47);
  }
  15.3846153846% {
    transform: translateX(49vw) translateY(2vh) scale(0.45);
  }
  19.2307692308% {
    transform: translateX(2vw) translateY(18vh) scale(0.93);
  }
  23.0769230769% {
    transform: translateX(47vw) translateY(24vh) scale(0.71);
  }
  26.9230769231% {
    transform: translateX(13vw) translateY(-13vh) scale(0.94);
  }
  30.7692307692% {
    transform: translateX(21vw) translateY(-8vh) scale(0.51);
  }
  34.6153846154% {
    transform: translateX(-14vw) translateY(-4vh) scale(0.95);
  }
  38.4615384615% {
    transform: translateX(-7vw) translateY(17vh) scale(0.99);
  }
  42.3076923077% {
    transform: translateX(3vw) translateY(-22vh) scale(0.36);
  }
  46.1538461538% {
    transform: translateX(14vw) translateY(11vh) scale(0.39);
  }
  50% {
    transform: translateX(24vw) translateY(45vh) scale(0.86);
  }
  53.8461538462% {
    transform: translateX(-6vw) translateY(-26vh) scale(0.55);
  }
  57.6923076923% {
    transform: translateX(17vw) translateY(-45vh) scale(0.81);
  }
  61.5384615385% {
    transform: translateX(36vw) translateY(-18vh) scale(0.55);
  }
  65.3846153846% {
    transform: translateX(33vw) translateY(41vh) scale(0.55);
  }
  69.2307692308% {
    transform: translateX(35vw) translateY(-41vh) scale(0.99);
  }
  73.0769230769% {
    transform: translateX(38vw) translateY(40vh) scale(0.87);
  }
  76.9230769231% {
    transform: translateX(-27vw) translateY(-46vh) scale(0.51);
  }
  80.7692307692% {
    transform: translateX(-16vw) translateY(-28vh) scale(0.3);
  }
  84.6153846154% {
    transform: translateX(-34vw) translateY(45vh) scale(0.73);
  }
  88.4615384615% {
    transform: translateX(-44vw) translateY(5vh) scale(0.96);
  }
  92.3076923077% {
    transform: translateX(15vw) translateY(39vh) scale(0.83);
  }
  96.1538461538% {
    transform: translateX(-17vw) translateY(19vh) scale(0.72);
  }
  100% {
    transform: translateX(-26vw) translateY(15vh) scale(0.98);
  }
}
.firefly:nth-child(19) {
  animation-name: move19;
}
.firefly:nth-child(19)::before {
  animation-duration: 18s;
}
.firefly:nth-child(19)::after {
  animation-duration: 18s, 10184ms;
  animation-delay: 0ms, 813ms;
}

@keyframes move19 {
  0% {
    transform: translateX(25vw) translateY(-16vh) scale(0.71);
  }
  5.2631578947% {
    transform: translateX(10vw) translateY(34vh) scale(0.83);
  }
  10.5263157895% {
    transform: translateX(43vw) translateY(18vh) scale(0.76);
  }
  15.7894736842% {
    transform: translateX(-2vw) translateY(-28vh) scale(0.31);
  }
  21.0526315789% {
    transform: translateX(-12vw) translateY(12vh) scale(0.5);
  }
  26.3157894737% {
    transform: translateX(21vw) translateY(12vh) scale(0.51);
  }
  31.5789473684% {
    transform: translateX(21vw) translateY(-11vh) scale(0.71);
  }
  36.8421052632% {
    transform: translateX(-31vw) translateY(48vh) scale(0.76);
  }
  42.1052631579% {
    transform: translateX(-25vw) translateY(-8vh) scale(0.52);
  }
  47.3684210526% {
    transform: translateX(-33vw) translateY(-2vh) scale(0.32);
  }
  52.6315789474% {
    transform: translateX(28vw) translateY(28vh) scale(0.65);
  }
  57.8947368421% {
    transform: translateX(26vw) translateY(-17vh) scale(0.79);
  }
  63.1578947368% {
    transform: translateX(-6vw) translateY(38vh) scale(0.79);
  }
  68.4210526316% {
    transform: translateX(-44vw) translateY(-48vh) scale(0.82);
  }
  73.6842105263% {
    transform: translateX(-9vw) translateY(-34vh) scale(0.7);
  }
  78.9473684211% {
    transform: translateX(-38vw) translateY(0vh) scale(0.39);
  }
  84.2105263158% {
    transform: translateX(-18vw) translateY(12vh) scale(0.42);
  }
  89.4736842105% {
    transform: translateX(-16vw) translateY(24vh) scale(0.53);
  }
  94.7368421053% {
    transform: translateX(6vw) translateY(-1vh) scale(0.91);
  }
  100% {
    transform: translateX(-42vw) translateY(15vh) scale(0.53);
  }
}
.firefly:nth-child(20) {
  animation-name: move20;
}
.firefly:nth-child(20)::before {
  animation-duration: 14s;
}
.firefly:nth-child(20)::after {
  animation-duration: 14s, 10236ms;
  animation-delay: 0ms, 6454ms;
}

@keyframes move20 {
  0% {
    transform: translateX(-41vw) translateY(32vh) scale(0.72);
  }
  4.1666666667% {
    transform: translateX(-37vw) translateY(50vh) scale(0.52);
  }
  8.3333333333% {
    transform: translateX(-33vw) translateY(34vh) scale(0.4);
  }
  12.5% {
    transform: translateX(-26vw) translateY(12vh) scale(0.61);
  }
  16.6666666667% {
    transform: translateX(6vw) translateY(-33vh) scale(0.55);
  }
  20.8333333333% {
    transform: translateX(32vw) translateY(30vh) scale(0.56);
  }
  25% {
    transform: translateX(-6vw) translateY(27vh) scale(0.9);
  }
  29.1666666667% {
    transform: translateX(-7vw) translateY(1vh) scale(0.61);
  }
  33.3333333333% {
    transform: translateX(5vw) translateY(2vh) scale(0.26);
  }
  37.5% {
    transform: translateX(-7vw) translateY(13vh) scale(0.26);
  }
  41.6666666667% {
    transform: translateX(8vw) translateY(-19vh) scale(0.42);
  }
  45.8333333333% {
    transform: translateX(39vw) translateY(40vh) scale(0.41);
  }
  50% {
    transform: translateX(23vw) translateY(37vh) scale(0.87);
  }
  54.1666666667% {
    transform: translateX(-32vw) translateY(-9vh) scale(0.42);
  }
  58.3333333333% {
    transform: translateX(-14vw) translateY(-11vh) scale(0.42);
  }
  62.5% {
    transform: translateX(-7vw) translateY(12vh) scale(0.89);
  }
  66.6666666667% {
    transform: translateX(38vw) translateY(45vh) scale(0.67);
  }
  70.8333333333% {
    transform: translateX(26vw) translateY(-46vh) scale(0.68);
  }
  75% {
    transform: translateX(38vw) translateY(40vh) scale(0.91);
  }
  79.1666666667% {
    transform: translateX(43vw) translateY(-11vh) scale(0.35);
  }
  83.3333333333% {
    transform: translateX(27vw) translateY(6vh) scale(0.86);
  }
  87.5% {
    transform: translateX(-2vw) translateY(-16vh) scale(0.82);
  }
  91.6666666667% {
    transform: translateX(-13vw) translateY(-2vh) scale(0.59);
  }
  95.8333333333% {
    transform: translateX(-18vw) translateY(-30vh) scale(0.72);
  }
  100% {
    transform: translateX(-15vw) translateY(-27vh) scale(0.28);
  }
}
.firefly:nth-child(21) {
  animation-name: move21;
}
.firefly:nth-child(21)::before {
  animation-duration: 9s;
}
.firefly:nth-child(21)::after {
  animation-duration: 9s, 7352ms;
  animation-delay: 0ms, 5672ms;
}

@keyframes move21 {
  0% {
    transform: translateX(-16vw) translateY(42vh) scale(0.56);
  }
  5.2631578947% {
    transform: translateX(-39vw) translateY(12vh) scale(0.55);
  }
  10.5263157895% {
    transform: translateX(13vw) translateY(-36vh) scale(0.88);
  }
  15.7894736842% {
    transform: translateX(10vw) translateY(49vh) scale(0.62);
  }
  21.0526315789% {
    transform: translateX(43vw) translateY(20vh) scale(0.29);
  }
  26.3157894737% {
    transform: translateX(4vw) translateY(-22vh) scale(0.78);
  }
  31.5789473684% {
    transform: translateX(18vw) translateY(-37vh) scale(1);
  }
  36.8421052632% {
    transform: translateX(33vw) translateY(8vh) scale(0.44);
  }
  42.1052631579% {
    transform: translateX(2vw) translateY(-46vh) scale(0.72);
  }
  47.3684210526% {
    transform: translateX(-41vw) translateY(19vh) scale(0.96);
  }
  52.6315789474% {
    transform: translateX(30vw) translateY(22vh) scale(0.58);
  }
  57.8947368421% {
    transform: translateX(39vw) translateY(40vh) scale(0.45);
  }
  63.1578947368% {
    transform: translateX(-20vw) translateY(-27vh) scale(0.85);
  }
  68.4210526316% {
    transform: translateX(-48vw) translateY(-18vh) scale(0.6);
  }
  73.6842105263% {
    transform: translateX(44vw) translateY(-2vh) scale(0.79);
  }
  78.9473684211% {
    transform: translateX(32vw) translateY(-17vh) scale(0.74);
  }
  84.2105263158% {
    transform: translateX(-23vw) translateY(49vh) scale(0.55);
  }
  89.4736842105% {
    transform: translateX(-24vw) translateY(5vh) scale(0.97);
  }
  94.7368421053% {
    transform: translateX(17vw) translateY(16vh) scale(0.41);
  }
  100% {
    transform: translateX(4vw) translateY(1vh) scale(0.8);
  }
}
.firefly:nth-child(22) {
  animation-name: move22;
}
.firefly:nth-child(22)::before {
  animation-duration: 15s;
}
.firefly:nth-child(22)::after {
  animation-duration: 15s, 8674ms;
  animation-delay: 0ms, 2261ms;
}

@keyframes move22 {
  0% {
    transform: translateX(5vw) translateY(37vh) scale(0.9);
  }
  4.7619047619% {
    transform: translateX(-14vw) translateY(41vh) scale(0.36);
  }
  9.5238095238% {
    transform: translateX(9vw) translateY(-42vh) scale(0.82);
  }
  14.2857142857% {
    transform: translateX(6vw) translateY(35vh) scale(0.45);
  }
  19.0476190476% {
    transform: translateX(-43vw) translateY(47vh) scale(0.77);
  }
  23.8095238095% {
    transform: translateX(-28vw) translateY(-18vh) scale(0.68);
  }
  28.5714285714% {
    transform: translateX(30vw) translateY(2vh) scale(0.44);
  }
  33.3333333333% {
    transform: translateX(5vw) translateY(12vh) scale(0.95);
  }
  38.0952380952% {
    transform: translateX(18vw) translateY(5vh) scale(0.38);
  }
  42.8571428571% {
    transform: translateX(-47vw) translateY(21vh) scale(0.38);
  }
  47.619047619% {
    transform: translateX(-37vw) translateY(29vh) scale(0.47);
  }
  52.380952381% {
    transform: translateX(-8vw) translateY(46vh) scale(0.6);
  }
  57.1428571429% {
    transform: translateX(-31vw) translateY(-40vh) scale(0.69);
  }
  61.9047619048% {
    transform: translateX(-23vw) translateY(24vh) scale(0.47);
  }
  66.6666666667% {
    transform: translateX(-5vw) translateY(32vh) scale(0.31);
  }
  71.4285714286% {
    transform: translateX(9vw) translateY(26vh) scale(0.29);
  }
  76.1904761905% {
    transform: translateX(15vw) translateY(-40vh) scale(0.98);
  }
  80.9523809524% {
    transform: translateX(-26vw) translateY(20vh) scale(0.82);
  }
  85.7142857143% {
    transform: translateX(-32vw) translateY(34vh) scale(0.46);
  }
  90.4761904762% {
    transform: translateX(-31vw) translateY(8vh) scale(0.59);
  }
  95.2380952381% {
    transform: translateX(-13vw) translateY(33vh) scale(0.91);
  }
  100% {
    transform: translateX(16vw) translateY(17vh) scale(0.94);
  }
}
.firefly:nth-child(23) {
  animation-name: move23;
}
.firefly:nth-child(23)::before {
  animation-duration: 17s;
}
.firefly:nth-child(23)::after {
  animation-duration: 17s, 5781ms;
  animation-delay: 0ms, 8396ms;
}

@keyframes move23 {
  0% {
    transform: translateX(-6vw) translateY(-48vh) scale(0.66);
  }
  5.5555555556% {
    transform: translateX(-13vw) translateY(-13vh) scale(0.93);
  }
  11.1111111111% {
    transform: translateX(-49vw) translateY(-22vh) scale(0.9);
  }
  16.6666666667% {
    transform: translateX(16vw) translateY(30vh) scale(0.98);
  }
  22.2222222222% {
    transform: translateX(50vw) translateY(-2vh) scale(0.93);
  }
  27.7777777778% {
    transform: translateX(24vw) translateY(-28vh) scale(0.81);
  }
  33.3333333333% {
    transform: translateX(19vw) translateY(-39vh) scale(0.88);
  }
  38.8888888889% {
    transform: translateX(24vw) translateY(49vh) scale(1);
  }
  44.4444444444% {
    transform: translateX(-30vw) translateY(3vh) scale(0.3);
  }
  50% {
    transform: translateX(-39vw) translateY(7vh) scale(0.93);
  }
  55.5555555556% {
    transform: translateX(-20vw) translateY(-22vh) scale(0.9);
  }
  61.1111111111% {
    transform: translateX(-29vw) translateY(21vh) scale(0.56);
  }
  66.6666666667% {
    transform: translateX(-7vw) translateY(-37vh) scale(0.55);
  }
  72.2222222222% {
    transform: translateX(-14vw) translateY(31vh) scale(0.99);
  }
  77.7777777778% {
    transform: translateX(4vw) translateY(-30vh) scale(0.51);
  }
  83.3333333333% {
    transform: translateX(-45vw) translateY(-34vh) scale(0.8);
  }
  88.8888888889% {
    transform: translateX(28vw) translateY(16vh) scale(0.77);
  }
  94.4444444444% {
    transform: translateX(-16vw) translateY(-2vh) scale(0.81);
  }
  100% {
    transform: translateX(-28vw) translateY(-12vh) scale(0.86);
  }
}
.firefly:nth-child(24) {
  animation-name: move24;
}
.firefly:nth-child(24)::before {
  animation-duration: 16s;
}
.firefly:nth-child(24)::after {
  animation-duration: 16s, 9316ms;
  animation-delay: 0ms, 2688ms;
}

@keyframes move24 {
  0% {
    transform: translateX(-10vw) translateY(-24vh) scale(0.79);
  }
  4.5454545455% {
    transform: translateX(-8vw) translateY(-15vh) scale(0.48);
  }
  9.0909090909% {
    transform: translateX(-5vw) translateY(-36vh) scale(0.6);
  }
  13.6363636364% {
    transform: translateX(43vw) translateY(-24vh) scale(0.75);
  }
  18.1818181818% {
    transform: translateX(-40vw) translateY(48vh) scale(0.92);
  }
  22.7272727273% {
    transform: translateX(-24vw) translateY(38vh) scale(0.7);
  }
  27.2727272727% {
    transform: translateX(4vw) translateY(-42vh) scale(0.43);
  }
  31.8181818182% {
    transform: translateX(26vw) translateY(-38vh) scale(0.77);
  }
  36.3636363636% {
    transform: translateX(-12vw) translateY(19vh) scale(0.82);
  }
  40.9090909091% {
    transform: translateX(-27vw) translateY(23vh) scale(0.67);
  }
  45.4545454545% {
    transform: translateX(22vw) translateY(40vh) scale(0.38);
  }
  50% {
    transform: translateX(37vw) translateY(-10vh) scale(0.48);
  }
  54.5454545455% {
    transform: translateX(-32vw) translateY(-16vh) scale(0.52);
  }
  59.0909090909% {
    transform: translateX(-16vw) translateY(21vh) scale(0.67);
  }
  63.6363636364% {
    transform: translateX(43vw) translateY(-22vh) scale(0.95);
  }
  68.1818181818% {
    transform: translateX(46vw) translateY(-11vh) scale(0.84);
  }
  72.7272727273% {
    transform: translateX(-16vw) translateY(4vh) scale(0.3);
  }
  77.2727272727% {
    transform: translateX(-15vw) translateY(48vh) scale(0.62);
  }
  81.8181818182% {
    transform: translateX(0vw) translateY(0vh) scale(0.84);
  }
  86.3636363636% {
    transform: translateX(13vw) translateY(-17vh) scale(0.32);
  }
  90.9090909091% {
    transform: translateX(-9vw) translateY(35vh) scale(0.88);
  }
  95.4545454545% {
    transform: translateX(0vw) translateY(22vh) scale(0.81);
  }
  100% {
    transform: translateX(-6vw) translateY(4vh) scale(0.96);
  }
}
.firefly:nth-child(25) {
  animation-name: move25;
}
.firefly:nth-child(25)::before {
  animation-duration: 13s;
}
.firefly:nth-child(25)::after {
  animation-duration: 13s, 8464ms;
  animation-delay: 0ms, 3725ms;
}

@keyframes move25 {
  0% {
    transform: translateX(20vw) translateY(10vh) scale(0.38);
  }
  5% {
    transform: translateX(-11vw) translateY(-45vh) scale(0.46);
  }
  10% {
    transform: translateX(19vw) translateY(-37vh) scale(0.85);
  }
  15% {
    transform: translateX(-37vw) translateY(-28vh) scale(0.62);
  }
  20% {
    transform: translateX(4vw) translateY(16vh) scale(0.77);
  }
  25% {
    transform: translateX(-29vw) translateY(31vh) scale(0.44);
  }
  30% {
    transform: translateX(-36vw) translateY(-49vh) scale(0.78);
  }
  35% {
    transform: translateX(-5vw) translateY(0vh) scale(0.52);
  }
  40% {
    transform: translateX(-34vw) translateY(-10vh) scale(0.71);
  }
  45% {
    transform: translateX(18vw) translateY(-39vh) scale(0.87);
  }
  50% {
    transform: translateX(24vw) translateY(25vh) scale(0.7);
  }
  55% {
    transform: translateX(-41vw) translateY(-31vh) scale(0.26);
  }
  60% {
    transform: translateX(-23vw) translateY(1vh) scale(0.29);
  }
  65% {
    transform: translateX(24vw) translateY(38vh) scale(0.96);
  }
  70% {
    transform: translateX(-13vw) translateY(0vh) scale(0.96);
  }
  75% {
    transform: translateX(31vw) translateY(30vh) scale(0.88);
  }
  80% {
    transform: translateX(0vw) translateY(-6vh) scale(0.26);
  }
  85% {
    transform: translateX(-34vw) translateY(42vh) scale(0.64);
  }
  90% {
    transform: translateX(13vw) translateY(18vh) scale(0.5);
  }
  95% {
    transform: translateX(-25vw) translateY(-10vh) scale(0.99);
  }
  100% {
    transform: translateX(20vw) translateY(8vh) scale(0.77);
  }
}
.firefly:nth-child(26) {
  animation-name: move26;
}
.firefly:nth-child(26)::before {
  animation-duration: 14s;
}
.firefly:nth-child(26)::after {
  animation-duration: 14s, 9245ms;
  animation-delay: 0ms, 2677ms;
}

@keyframes move26 {
  0% {
    transform: translateX(-34vw) translateY(24vh) scale(0.72);
  }
  3.8461538462% {
    transform: translateX(-9vw) translateY(33vh) scale(0.94);
  }
  7.6923076923% {
    transform: translateX(-44vw) translateY(11vh) scale(0.7);
  }
  11.5384615385% {
    transform: translateX(-6vw) translateY(47vh) scale(0.94);
  }
  15.3846153846% {
    transform: translateX(28vw) translateY(-6vh) scale(0.27);
  }
  19.2307692308% {
    transform: translateX(-21vw) translateY(-4vh) scale(0.7);
  }
  23.0769230769% {
    transform: translateX(13vw) translateY(-18vh) scale(0.95);
  }
  26.9230769231% {
    transform: translateX(-30vw) translateY(-19vh) scale(0.76);
  }
  30.7692307692% {
    transform: translateX(2vw) translateY(22vh) scale(0.28);
  }
  34.6153846154% {
    transform: translateX(-10vw) translateY(-40vh) scale(0.73);
  }
  38.4615384615% {
    transform: translateX(13vw) translateY(44vh) scale(0.52);
  }
  42.3076923077% {
    transform: translateX(-9vw) translateY(-38vh) scale(0.28);
  }
  46.1538461538% {
    transform: translateX(50vw) translateY(12vh) scale(0.92);
  }
  50% {
    transform: translateX(8vw) translateY(30vh) scale(0.85);
  }
  53.8461538462% {
    transform: translateX(-31vw) translateY(15vh) scale(0.94);
  }
  57.6923076923% {
    transform: translateX(19vw) translateY(45vh) scale(0.86);
  }
  61.5384615385% {
    transform: translateX(32vw) translateY(-14vh) scale(0.36);
  }
  65.3846153846% {
    transform: translateX(9vw) translateY(-32vh) scale(0.51);
  }
  69.2307692308% {
    transform: translateX(-25vw) translateY(-48vh) scale(0.39);
  }
  73.0769230769% {
    transform: translateX(50vw) translateY(-17vh) scale(0.9);
  }
  76.9230769231% {
    transform: translateX(20vw) translateY(-35vh) scale(0.86);
  }
  80.7692307692% {
    transform: translateX(39vw) translateY(3vh) scale(0.96);
  }
  84.6153846154% {
    transform: translateX(20vw) translateY(27vh) scale(0.72);
  }
  88.4615384615% {
    transform: translateX(5vw) translateY(-28vh) scale(0.29);
  }
  92.3076923077% {
    transform: translateX(2vw) translateY(21vh) scale(0.78);
  }
  96.1538461538% {
    transform: translateX(-16vw) translateY(13vh) scale(0.5);
  }
  100% {
    transform: translateX(41vw) translateY(-17vh) scale(0.48);
  }
}
.firefly:nth-child(27) {
  animation-name: move27;
}
.firefly:nth-child(27)::before {
  animation-duration: 11s;
}
.firefly:nth-child(27)::after {
  animation-duration: 11s, 8966ms;
  animation-delay: 0ms, 3814ms;
}

@keyframes move27 {
  0% {
    transform: translateX(9vw) translateY(-48vh) scale(0.4);
  }
  4.7619047619% {
    transform: translateX(-23vw) translateY(-49vh) scale(0.88);
  }
  9.5238095238% {
    transform: translateX(30vw) translateY(5vh) scale(0.39);
  }
  14.2857142857% {
    transform: translateX(-16vw) translateY(-22vh) scale(0.47);
  }
  19.0476190476% {
    transform: translateX(41vw) translateY(-9vh) scale(0.51);
  }
  23.8095238095% {
    transform: translateX(-40vw) translateY(14vh) scale(0.38);
  }
  28.5714285714% {
    transform: translateX(-35vw) translateY(6vh) scale(0.62);
  }
  33.3333333333% {
    transform: translateX(-4vw) translateY(48vh) scale(0.92);
  }
  38.0952380952% {
    transform: translateX(35vw) translateY(-37vh) scale(0.85);
  }
  42.8571428571% {
    transform: translateX(-21vw) translateY(-17vh) scale(0.5);
  }
  47.619047619% {
    transform: translateX(28vw) translateY(41vh) scale(0.31);
  }
  52.380952381% {
    transform: translateX(-11vw) translateY(37vh) scale(0.41);
  }
  57.1428571429% {
    transform: translateX(9vw) translateY(38vh) scale(0.51);
  }
  61.9047619048% {
    transform: translateX(-17vw) translateY(-31vh) scale(0.48);
  }
  66.6666666667% {
    transform: translateX(46vw) translateY(47vh) scale(0.45);
  }
  71.4285714286% {
    transform: translateX(-8vw) translateY(12vh) scale(0.86);
  }
  76.1904761905% {
    transform: translateX(-6vw) translateY(46vh) scale(0.73);
  }
  80.9523809524% {
    transform: translateX(-38vw) translateY(2vh) scale(0.26);
  }
  85.7142857143% {
    transform: translateX(-24vw) translateY(29vh) scale(0.87);
  }
  90.4761904762% {
    transform: translateX(-41vw) translateY(33vh) scale(0.3);
  }
  95.2380952381% {
    transform: translateX(0vw) translateY(39vh) scale(0.93);
  }
  100% {
    transform: translateX(-42vw) translateY(-47vh) scale(0.46);
  }
}
.firefly:nth-child(28) {
  animation-name: move28;
}
.firefly:nth-child(28)::before {
  animation-duration: 13s;
}
.firefly:nth-child(28)::after {
  animation-duration: 13s, 9660ms;
  animation-delay: 0ms, 2704ms;
}

@keyframes move28 {
  0% {
    transform: translateX(5vw) translateY(-43vh) scale(0.51);
  }
  5.2631578947% {
    transform: translateX(15vw) translateY(-45vh) scale(0.82);
  }
  10.5263157895% {
    transform: translateX(13vw) translateY(-48vh) scale(0.83);
  }
  15.7894736842% {
    transform: translateX(35vw) translateY(19vh) scale(0.56);
  }
  21.0526315789% {
    transform: translateX(-32vw) translateY(42vh) scale(0.74);
  }
  26.3157894737% {
    transform: translateX(-23vw) translateY(-33vh) scale(0.43);
  }
  31.5789473684% {
    transform: translateX(1vw) translateY(-43vh) scale(0.57);
  }
  36.8421052632% {
    transform: translateX(17vw) translateY(-32vh) scale(0.97);
  }
  42.1052631579% {
    transform: translateX(50vw) translateY(-10vh) scale(0.69);
  }
  47.3684210526% {
    transform: translateX(-8vw) translateY(39vh) scale(0.63);
  }
  52.6315789474% {
    transform: translateX(-35vw) translateY(19vh) scale(0.8);
  }
  57.8947368421% {
    transform: translateX(-33vw) translateY(-44vh) scale(0.35);
  }
  63.1578947368% {
    transform: translateX(44vw) translateY(6vh) scale(0.55);
  }
  68.4210526316% {
    transform: translateX(-21vw) translateY(-45vh) scale(1);
  }
  73.6842105263% {
    transform: translateX(32vw) translateY(-49vh) scale(0.93);
  }
  78.9473684211% {
    transform: translateX(-38vw) translateY(-23vh) scale(0.62);
  }
  84.2105263158% {
    transform: translateX(-49vw) translateY(-44vh) scale(0.83);
  }
  89.4736842105% {
    transform: translateX(33vw) translateY(13vh) scale(0.28);
  }
  94.7368421053% {
    transform: translateX(0vw) translateY(-16vh) scale(0.46);
  }
  100% {
    transform: translateX(-21vw) translateY(0vh) scale(0.45);
  }
}
.firefly:nth-child(29) {
  animation-name: move29;
}
.firefly:nth-child(29)::before {
  animation-duration: 13s;
}
.firefly:nth-child(29)::after {
  animation-duration: 13s, 9278ms;
  animation-delay: 0ms, 1639ms;
}

@keyframes move29 {
  0% {
    transform: translateX(2vw) translateY(-18vh) scale(0.61);
  }
  4.5454545455% {
    transform: translateX(-40vw) translateY(-33vh) scale(0.44);
  }
  9.0909090909% {
    transform: translateX(-39vw) translateY(-43vh) scale(0.48);
  }
  13.6363636364% {
    transform: translateX(5vw) translateY(3vh) scale(0.44);
  }
  18.1818181818% {
    transform: translateX(16vw) translateY(45vh) scale(0.8);
  }
  22.7272727273% {
    transform: translateX(30vw) translateY(13vh) scale(0.71);
  }
  27.2727272727% {
    transform: translateX(18vw) translateY(27vh) scale(0.82);
  }
  31.8181818182% {
    transform: translateX(-6vw) translateY(-42vh) scale(0.39);
  }
  36.3636363636% {
    transform: translateX(-30vw) translateY(-19vh) scale(0.74);
  }
  40.9090909091% {
    transform: translateX(8vw) translateY(2vh) scale(0.55);
  }
  45.4545454545% {
    transform: translateX(-23vw) translateY(-3vh) scale(0.61);
  }
  50% {
    transform: translateX(-16vw) translateY(28vh) scale(0.67);
  }
  54.5454545455% {
    transform: translateX(-25vw) translateY(6vh) scale(0.73);
  }
  59.0909090909% {
    transform: translateX(46vw) translateY(19vh) scale(0.97);
  }
  63.6363636364% {
    transform: translateX(-33vw) translateY(-9vh) scale(0.97);
  }
  68.1818181818% {
    transform: translateX(45vw) translateY(-26vh) scale(0.58);
  }
  72.7272727273% {
    transform: translateX(42vw) translateY(-41vh) scale(0.96);
  }
  77.2727272727% {
    transform: translateX(38vw) translateY(40vh) scale(0.75);
  }
  81.8181818182% {
    transform: translateX(22vw) translateY(-47vh) scale(0.95);
  }
  86.3636363636% {
    transform: translateX(47vw) translateY(-31vh) scale(0.6);
  }
  90.9090909091% {
    transform: translateX(6vw) translateY(-45vh) scale(0.65);
  }
  95.4545454545% {
    transform: translateX(-36vw) translateY(16vh) scale(0.26);
  }
  100% {
    transform: translateX(35vw) translateY(35vh) scale(0.82);
  }
}
.firefly:nth-child(30) {
  animation-name: move30;
}
.firefly:nth-child(30)::before {
  animation-duration: 12s;
}
.firefly:nth-child(30)::after {
  animation-duration: 12s, 7047ms;
  animation-delay: 0ms, 7629ms;
}

@keyframes move30 {
  0% {
    transform: translateX(-38vw) translateY(12vh) scale(0.87);
  }
  4% {
    transform: translateX(-37vw) translateY(-31vh) scale(0.71);
  }
  8% {
    transform: translateX(-39vw) translateY(36vh) scale(0.44);
  }
  12% {
    transform: translateX(-18vw) translateY(13vh) scale(0.66);
  }
  16% {
    transform: translateX(-47vw) translateY(7vh) scale(0.96);
  }
  20% {
    transform: translateX(-23vw) translateY(-11vh) scale(0.77);
  }
  24% {
    transform: translateX(8vw) translateY(29vh) scale(0.63);
  }
  28% {
    transform: translateX(27vw) translateY(-34vh) scale(0.52);
  }
  32% {
    transform: translateX(-45vw) translateY(26vh) scale(0.65);
  }
  36% {
    transform: translateX(-1vw) translateY(7vh) scale(0.31);
  }
  40% {
    transform: translateX(4vw) translateY(-32vh) scale(0.59);
  }
  44% {
    transform: translateX(34vw) translateY(38vh) scale(0.4);
  }
  48% {
    transform: translateX(-7vw) translateY(14vh) scale(0.47);
  }
  52% {
    transform: translateX(44vw) translateY(39vh) scale(0.81);
  }
  56% {
    transform: translateX(-26vw) translateY(-18vh) scale(0.4);
  }
  60% {
    transform: translateX(-1vw) translateY(-33vh) scale(0.38);
  }
  64% {
    transform: translateX(39vw) translateY(49vh) scale(0.34);
  }
  68% {
    transform: translateX(-11vw) translateY(33vh) scale(0.67);
  }
  72% {
    transform: translateX(-44vw) translateY(-46vh) scale(0.4);
  }
  76% {
    transform: translateX(35vw) translateY(5vh) scale(0.6);
  }
  80% {
    transform: translateX(-49vw) translateY(-31vh) scale(0.4);
  }
  84% {
    transform: translateX(36vw) translateY(-40vh) scale(0.32);
  }
  88% {
    transform: translateX(40vw) translateY(17vh) scale(0.87);
  }
  92% {
    transform: translateX(38vw) translateY(-12vh) scale(0.92);
  }
  96% {
    transform: translateX(-6vw) translateY(-44vh) scale(0.93);
  }
  100% {
    transform: translateX(29vw) translateY(4vh) scale(0.62);
  }
}
.firefly:nth-child(31) {
  animation-name: move31;
}
.firefly:nth-child(31)::before {
  animation-duration: 17s;
}
.firefly:nth-child(31)::after {
  animation-duration: 17s, 5890ms;
  animation-delay: 0ms, 2861ms;
}

@keyframes move31 {
  0% {
    transform: translateX(50vw) translateY(5vh) scale(0.28);
  }
  3.5714285714% {
    transform: translateX(-18vw) translateY(-6vh) scale(0.29);
  }
  7.1428571429% {
    transform: translateX(-30vw) translateY(36vh) scale(0.75);
  }
  10.7142857143% {
    transform: translateX(-15vw) translateY(-47vh) scale(0.39);
  }
  14.2857142857% {
    transform: translateX(-7vw) translateY(-19vh) scale(0.63);
  }
  17.8571428571% {
    transform: translateX(19vw) translateY(-27vh) scale(0.43);
  }
  21.4285714286% {
    transform: translateX(1vw) translateY(1vh) scale(0.96);
  }
  25% {
    transform: translateX(37vw) translateY(19vh) scale(0.54);
  }
  28.5714285714% {
    transform: translateX(42vw) translateY(-40vh) scale(0.31);
  }
  32.1428571429% {
    transform: translateX(14vw) translateY(-18vh) scale(1);
  }
  35.7142857143% {
    transform: translateX(-42vw) translateY(4vh) scale(0.55);
  }
  39.2857142857% {
    transform: translateX(20vw) translateY(43vh) scale(0.43);
  }
  42.8571428571% {
    transform: translateX(-46vw) translateY(28vh) scale(0.66);
  }
  46.4285714286% {
    transform: translateX(37vw) translateY(-34vh) scale(0.64);
  }
  50% {
    transform: translateX(40vw) translateY(1vh) scale(0.36);
  }
  53.5714285714% {
    transform: translateX(-4vw) translateY(-15vh) scale(0.83);
  }
  57.1428571429% {
    transform: translateX(48vw) translateY(10vh) scale(0.26);
  }
  60.7142857143% {
    transform: translateX(-36vw) translateY(-22vh) scale(0.29);
  }
  64.2857142857% {
    transform: translateX(19vw) translateY(32vh) scale(0.34);
  }
  67.8571428571% {
    transform: translateX(-22vw) translateY(-33vh) scale(0.76);
  }
  71.4285714286% {
    transform: translateX(15vw) translateY(-48vh) scale(0.56);
  }
  75% {
    transform: translateX(15vw) translateY(18vh) scale(0.33);
  }
  78.5714285714% {
    transform: translateX(46vw) translateY(-29vh) scale(0.71);
  }
  82.1428571429% {
    transform: translateX(-30vw) translateY(26vh) scale(0.38);
  }
  85.7142857143% {
    transform: translateX(32vw) translateY(39vh) scale(0.71);
  }
  89.2857142857% {
    transform: translateX(48vw) translateY(7vh) scale(0.68);
  }
  92.8571428571% {
    transform: translateX(5vw) translateY(-5vh) scale(0.59);
  }
  96.4285714286% {
    transform: translateX(48vw) translateY(-8vh) scale(0.8);
  }
  100% {
    transform: translateX(-10vw) translateY(-46vh) scale(0.27);
  }
}
.firefly:nth-child(32) {
  animation-name: move32;
}
.firefly:nth-child(32)::before {
  animation-duration: 15s;
}
.firefly:nth-child(32)::after {
  animation-duration: 15s, 7604ms;
  animation-delay: 0ms, 6849ms;
}

@keyframes move32 {
  0% {
    transform: translateX(7vw) translateY(-20vh) scale(0.75);
  }
  5.8823529412% {
    transform: translateX(11vw) translateY(24vh) scale(0.69);
  }
  11.7647058824% {
    transform: translateX(1vw) translateY(38vh) scale(0.58);
  }
  17.6470588235% {
    transform: translateX(-4vw) translateY(-14vh) scale(0.42);
  }
  23.5294117647% {
    transform: translateX(6vw) translateY(-4vh) scale(0.97);
  }
  29.4117647059% {
    transform: translateX(-16vw) translateY(28vh) scale(0.33);
  }
  35.2941176471% {
    transform: translateX(3vw) translateY(-14vh) scale(0.84);
  }
  41.1764705882% {
    transform: translateX(40vw) translateY(-41vh) scale(0.99);
  }
  47.0588235294% {
    transform: translateX(-34vw) translateY(17vh) scale(0.36);
  }
  52.9411764706% {
    transform: translateX(-20vw) translateY(-21vh) scale(0.52);
  }
  58.8235294118% {
    transform: translateX(-16vw) translateY(4vh) scale(0.84);
  }
  64.7058823529% {
    transform: translateX(-6vw) translateY(0vh) scale(0.67);
  }
  70.5882352941% {
    transform: translateX(20vw) translateY(-1vh) scale(0.95);
  }
  76.4705882353% {
    transform: translateX(-39vw) translateY(11vh) scale(0.89);
  }
  82.3529411765% {
    transform: translateX(-39vw) translateY(38vh) scale(0.9);
  }
  88.2352941176% {
    transform: translateX(-4vw) translateY(12vh) scale(0.71);
  }
  94.1176470588% {
    transform: translateX(28vw) translateY(-44vh) scale(0.44);
  }
  100% {
    transform: translateX(-47vw) translateY(16vh) scale(0.37);
  }
}
.firefly:nth-child(33) {
  animation-name: move33;
}
.firefly:nth-child(33)::before {
  animation-duration: 16s;
}
.firefly:nth-child(33)::after {
  animation-duration: 16s, 8957ms;
  animation-delay: 0ms, 3184ms;
}

@keyframes move33 {
  0% {
    transform: translateX(-29vw) translateY(22vh) scale(0.33);
  }
  3.8461538462% {
    transform: translateX(39vw) translateY(-35vh) scale(0.7);
  }
  7.6923076923% {
    transform: translateX(-48vw) translateY(-16vh) scale(0.53);
  }
  11.5384615385% {
    transform: translateX(36vw) translateY(-31vh) scale(1);
  }
  15.3846153846% {
    transform: translateX(-25vw) translateY(-32vh) scale(0.76);
  }
  19.2307692308% {
    transform: translateX(31vw) translateY(7vh) scale(0.84);
  }
  23.0769230769% {
    transform: translateX(31vw) translateY(-14vh) scale(0.73);
  }
  26.9230769231% {
    transform: translateX(40vw) translateY(2vh) scale(0.91);
  }
  30.7692307692% {
    transform: translateX(-36vw) translateY(28vh) scale(0.57);
  }
  34.6153846154% {
    transform: translateX(-14vw) translateY(31vh) scale(0.27);
  }
  38.4615384615% {
    transform: translateX(-39vw) translateY(50vh) scale(0.67);
  }
  42.3076923077% {
    transform: translateX(18vw) translateY(10vh) scale(0.97);
  }
  46.1538461538% {
    transform: translateX(9vw) translateY(-1vh) scale(0.86);
  }
  50% {
    transform: translateX(-47vw) translateY(-27vh) scale(0.72);
  }
  53.8461538462% {
    transform: translateX(27vw) translateY(-4vh) scale(0.87);
  }
  57.6923076923% {
    transform: translateX(-21vw) translateY(-27vh) scale(0.44);
  }
  61.5384615385% {
    transform: translateX(-3vw) translateY(-28vh) scale(0.7);
  }
  65.3846153846% {
    transform: translateX(50vw) translateY(-33vh) scale(0.26);
  }
  69.2307692308% {
    transform: translateX(-37vw) translateY(12vh) scale(0.41);
  }
  73.0769230769% {
    transform: translateX(-25vw) translateY(7vh) scale(0.97);
  }
  76.9230769231% {
    transform: translateX(-38vw) translateY(-46vh) scale(0.82);
  }
  80.7692307692% {
    transform: translateX(0vw) translateY(-28vh) scale(0.62);
  }
  84.6153846154% {
    transform: translateX(-31vw) translateY(18vh) scale(0.7);
  }
  88.4615384615% {
    transform: translateX(45vw) translateY(25vh) scale(0.65);
  }
  92.3076923077% {
    transform: translateX(7vw) translateY(33vh) scale(0.54);
  }
  96.1538461538% {
    transform: translateX(25vw) translateY(-12vh) scale(0.68);
  }
  100% {
    transform: translateX(22vw) translateY(-10vh) scale(0.86);
  }
}
.firefly:nth-child(34) {
  animation-name: move34;
}
.firefly:nth-child(34)::before {
  animation-duration: 12s;
}
.firefly:nth-child(34)::after {
  animation-duration: 12s, 5408ms;
  animation-delay: 0ms, 8417ms;
}

@keyframes move34 {
  0% {
    transform: translateX(28vw) translateY(-8vh) scale(0.72);
  }
  4.7619047619% {
    transform: translateX(39vw) translateY(-33vh) scale(0.77);
  }
  9.5238095238% {
    transform: translateX(39vw) translateY(-6vh) scale(0.83);
  }
  14.2857142857% {
    transform: translateX(32vw) translateY(28vh) scale(0.69);
  }
  19.0476190476% {
    transform: translateX(22vw) translateY(-14vh) scale(0.94);
  }
  23.8095238095% {
    transform: translateX(49vw) translateY(-29vh) scale(0.35);
  }
  28.5714285714% {
    transform: translateX(4vw) translateY(39vh) scale(0.78);
  }
  33.3333333333% {
    transform: translateX(-19vw) translateY(-5vh) scale(0.62);
  }
  38.0952380952% {
    transform: translateX(24vw) translateY(33vh) scale(0.51);
  }
  42.8571428571% {
    transform: translateX(29vw) translateY(-2vh) scale(0.76);
  }
  47.619047619% {
    transform: translateX(30vw) translateY(1vh) scale(0.37);
  }
  52.380952381% {
    transform: translateX(-48vw) translateY(37vh) scale(0.31);
  }
  57.1428571429% {
    transform: translateX(-36vw) translateY(32vh) scale(0.49);
  }
  61.9047619048% {
    transform: translateX(-5vw) translateY(-15vh) scale(0.47);
  }
  66.6666666667% {
    transform: translateX(4vw) translateY(46vh) scale(0.91);
  }
  71.4285714286% {
    transform: translateX(-6vw) translateY(43vh) scale(0.74);
  }
  76.1904761905% {
    transform: translateX(23vw) translateY(8vh) scale(0.97);
  }
  80.9523809524% {
    transform: translateX(36vw) translateY(-13vh) scale(0.57);
  }
  85.7142857143% {
    transform: translateX(44vw) translateY(16vh) scale(0.56);
  }
  90.4761904762% {
    transform: translateX(-8vw) translateY(20vh) scale(0.44);
  }
  95.2380952381% {
    transform: translateX(-17vw) translateY(-6vh) scale(0.51);
  }
  100% {
    transform: translateX(-47vw) translateY(1vh) scale(0.65);
  }
}
.firefly:nth-child(35) {
  animation-name: move35;
}
.firefly:nth-child(35)::before {
  animation-duration: 9s;
}
.firefly:nth-child(35)::after {
  animation-duration: 9s, 9963ms;
  animation-delay: 0ms, 928ms;
}

@keyframes move35 {
  0% {
    transform: translateX(37vw) translateY(-35vh) scale(0.7);
  }
  5% {
    transform: translateX(41vw) translateY(-17vh) scale(0.96);
  }
  10% {
    transform: translateX(29vw) translateY(-11vh) scale(0.79);
  }
  15% {
    transform: translateX(-45vw) translateY(26vh) scale(1);
  }
  20% {
    transform: translateX(-17vw) translateY(28vh) scale(1);
  }
  25% {
    transform: translateX(-39vw) translateY(-22vh) scale(0.46);
  }
  30% {
    transform: translateX(-49vw) translateY(46vh) scale(0.59);
  }
  35% {
    transform: translateX(44vw) translateY(19vh) scale(0.33);
  }
  40% {
    transform: translateX(15vw) translateY(7vh) scale(0.3);
  }
  45% {
    transform: translateX(-33vw) translateY(-38vh) scale(0.76);
  }
  50% {
    transform: translateX(32vw) translateY(-19vh) scale(0.86);
  }
  55% {
    transform: translateX(-29vw) translateY(-5vh) scale(0.43);
  }
  60% {
    transform: translateX(5vw) translateY(-39vh) scale(0.37);
  }
  65% {
    transform: translateX(22vw) translateY(41vh) scale(0.84);
  }
  70% {
    transform: translateX(-41vw) translateY(30vh) scale(0.35);
  }
  75% {
    transform: translateX(-9vw) translateY(-4vh) scale(0.86);
  }
  80% {
    transform: translateX(15vw) translateY(-22vh) scale(0.91);
  }
  85% {
    transform: translateX(-14vw) translateY(49vh) scale(0.27);
  }
  90% {
    transform: translateX(-11vw) translateY(-43vh) scale(0.92);
  }
  95% {
    transform: translateX(-29vw) translateY(-39vh) scale(0.61);
  }
  100% {
    transform: translateX(-21vw) translateY(-48vh) scale(0.81);
  }
}
.firefly:nth-child(36) {
  animation-name: move36;
}
.firefly:nth-child(36)::before {
  animation-duration: 18s;
}
.firefly:nth-child(36)::after {
  animation-duration: 18s, 5775ms;
  animation-delay: 0ms, 5503ms;
}

@keyframes move36 {
  0% {
    transform: translateX(19vw) translateY(-19vh) scale(0.33);
  }
  5.2631578947% {
    transform: translateX(-5vw) translateY(-43vh) scale(0.92);
  }
  10.5263157895% {
    transform: translateX(-23vw) translateY(30vh) scale(0.96);
  }
  15.7894736842% {
    transform: translateX(3vw) translateY(-18vh) scale(0.72);
  }
  21.0526315789% {
    transform: translateX(8vw) translateY(45vh) scale(0.89);
  }
  26.3157894737% {
    transform: translateX(-7vw) translateY(4vh) scale(0.35);
  }
  31.5789473684% {
    transform: translateX(24vw) translateY(3vh) scale(0.31);
  }
  36.8421052632% {
    transform: translateX(-31vw) translateY(-29vh) scale(0.62);
  }
  42.1052631579% {
    transform: translateX(9vw) translateY(24vh) scale(0.93);
  }
  47.3684210526% {
    transform: translateX(35vw) translateY(-12vh) scale(0.47);
  }
  52.6315789474% {
    transform: translateX(15vw) translateY(-49vh) scale(0.71);
  }
  57.8947368421% {
    transform: translateX(-16vw) translateY(21vh) scale(0.54);
  }
  63.1578947368% {
    transform: translateX(-16vw) translateY(45vh) scale(0.82);
  }
  68.4210526316% {
    transform: translateX(23vw) translateY(44vh) scale(0.88);
  }
  73.6842105263% {
    transform: translateX(8vw) translateY(-48vh) scale(0.37);
  }
  78.9473684211% {
    transform: translateX(15vw) translateY(49vh) scale(0.3);
  }
  84.2105263158% {
    transform: translateX(12vw) translateY(-19vh) scale(0.71);
  }
  89.4736842105% {
    transform: translateX(-41vw) translateY(14vh) scale(0.69);
  }
  94.7368421053% {
    transform: translateX(2vw) translateY(-46vh) scale(0.52);
  }
  100% {
    transform: translateX(-8vw) translateY(11vh) scale(0.5);
  }
}
.firefly:nth-child(37) {
  animation-name: move37;
}
.firefly:nth-child(37)::before {
  animation-duration: 13s;
}
.firefly:nth-child(37)::after {
  animation-duration: 13s, 7730ms;
  animation-delay: 0ms, 6178ms;
}

@keyframes move37 {
  0% {
    transform: translateX(-41vw) translateY(-1vh) scale(0.76);
  }
  3.7037037037% {
    transform: translateX(20vw) translateY(-12vh) scale(0.27);
  }
  7.4074074074% {
    transform: translateX(14vw) translateY(30vh) scale(0.87);
  }
  11.1111111111% {
    transform: translateX(-18vw) translateY(15vh) scale(0.83);
  }
  14.8148148148% {
    transform: translateX(-49vw) translateY(-42vh) scale(0.61);
  }
  18.5185185185% {
    transform: translateX(20vw) translateY(-37vh) scale(0.55);
  }
  22.2222222222% {
    transform: translateX(-41vw) translateY(-38vh) scale(0.99);
  }
  25.9259259259% {
    transform: translateX(-26vw) translateY(50vh) scale(0.3);
  }
  29.6296296296% {
    transform: translateX(45vw) translateY(43vh) scale(0.55);
  }
  33.3333333333% {
    transform: translateX(-37vw) translateY(-25vh) scale(0.95);
  }
  37.037037037% {
    transform: translateX(-5vw) translateY(41vh) scale(0.69);
  }
  40.7407407407% {
    transform: translateX(-26vw) translateY(-9vh) scale(0.85);
  }
  44.4444444444% {
    transform: translateX(-35vw) translateY(48vh) scale(0.62);
  }
  48.1481481481% {
    transform: translateX(-35vw) translateY(49vh) scale(0.68);
  }
  51.8518518519% {
    transform: translateX(-5vw) translateY(47vh) scale(0.94);
  }
  55.5555555556% {
    transform: translateX(-41vw) translateY(24vh) scale(0.58);
  }
  59.2592592593% {
    transform: translateX(27vw) translateY(-28vh) scale(0.27);
  }
  62.962962963% {
    transform: translateX(28vw) translateY(31vh) scale(0.33);
  }
  66.6666666667% {
    transform: translateX(-10vw) translateY(4vh) scale(0.61);
  }
  70.3703703704% {
    transform: translateX(-6vw) translateY(27vh) scale(0.39);
  }
  74.0740740741% {
    transform: translateX(-20vw) translateY(9vh) scale(0.88);
  }
  77.7777777778% {
    transform: translateX(18vw) translateY(-29vh) scale(0.31);
  }
  81.4814814815% {
    transform: translateX(5vw) translateY(-3vh) scale(0.61);
  }
  85.1851851852% {
    transform: translateX(-36vw) translateY(-22vh) scale(0.35);
  }
  88.8888888889% {
    transform: translateX(-6vw) translateY(41vh) scale(0.71);
  }
  92.5925925926% {
    transform: translateX(49vw) translateY(-17vh) scale(0.37);
  }
  96.2962962963% {
    transform: translateX(38vw) translateY(37vh) scale(0.47);
  }
  100% {
    transform: translateX(7vw) translateY(9vh) scale(0.74);
  }
}
.firefly:nth-child(38) {
  animation-name: move38;
}
.firefly:nth-child(38)::before {
  animation-duration: 9s;
}
.firefly:nth-child(38)::after {
  animation-duration: 9s, 9379ms;
  animation-delay: 0ms, 2943ms;
}

@keyframes move38 {
  0% {
    transform: translateX(22vw) translateY(-5vh) scale(0.39);
  }
  3.5714285714% {
    transform: translateX(-12vw) translateY(-41vh) scale(0.47);
  }
  7.1428571429% {
    transform: translateX(20vw) translateY(48vh) scale(0.87);
  }
  10.7142857143% {
    transform: translateX(-38vw) translateY(22vh) scale(0.27);
  }
  14.2857142857% {
    transform: translateX(43vw) translateY(2vh) scale(0.48);
  }
  17.8571428571% {
    transform: translateX(42vw) translateY(18vh) scale(0.76);
  }
  21.4285714286% {
    transform: translateX(47vw) translateY(-43vh) scale(0.76);
  }
  25% {
    transform: translateX(8vw) translateY(37vh) scale(0.48);
  }
  28.5714285714% {
    transform: translateX(-25vw) translateY(-6vh) scale(0.38);
  }
  32.1428571429% {
    transform: translateX(-19vw) translateY(-15vh) scale(0.51);
  }
  35.7142857143% {
    transform: translateX(-32vw) translateY(-39vh) scale(0.32);
  }
  39.2857142857% {
    transform: translateX(-8vw) translateY(-44vh) scale(0.99);
  }
  42.8571428571% {
    transform: translateX(-30vw) translateY(-5vh) scale(0.47);
  }
  46.4285714286% {
    transform: translateX(24vw) translateY(-49vh) scale(0.81);
  }
  50% {
    transform: translateX(43vw) translateY(21vh) scale(0.87);
  }
  53.5714285714% {
    transform: translateX(20vw) translateY(-39vh) scale(0.56);
  }
  57.1428571429% {
    transform: translateX(6vw) translateY(10vh) scale(0.82);
  }
  60.7142857143% {
    transform: translateX(24vw) translateY(-2vh) scale(0.32);
  }
  64.2857142857% {
    transform: translateX(-3vw) translateY(23vh) scale(0.7);
  }
  67.8571428571% {
    transform: translateX(15vw) translateY(41vh) scale(0.82);
  }
  71.4285714286% {
    transform: translateX(37vw) translateY(31vh) scale(0.29);
  }
  75% {
    transform: translateX(49vw) translateY(-35vh) scale(0.42);
  }
  78.5714285714% {
    transform: translateX(43vw) translateY(-22vh) scale(0.68);
  }
  82.1428571429% {
    transform: translateX(-5vw) translateY(-5vh) scale(0.9);
  }
  85.7142857143% {
    transform: translateX(-27vw) translateY(4vh) scale(0.49);
  }
  89.2857142857% {
    transform: translateX(-13vw) translateY(-20vh) scale(0.71);
  }
  92.8571428571% {
    transform: translateX(-30vw) translateY(42vh) scale(0.42);
  }
  96.4285714286% {
    transform: translateX(40vw) translateY(-36vh) scale(0.94);
  }
  100% {
    transform: translateX(-9vw) translateY(-29vh) scale(0.62);
  }
}
.firefly:nth-child(39) {
  animation-name: move39;
}
.firefly:nth-child(39)::before {
  animation-duration: 9s;
}
.firefly:nth-child(39)::after {
  animation-duration: 9s, 9860ms;
  animation-delay: 0ms, 5565ms;
}

@keyframes move39 {
  0% {
    transform: translateX(-27vw) translateY(19vh) scale(0.86);
  }
  4.5454545455% {
    transform: translateX(-27vw) translateY(-44vh) scale(0.45);
  }
  9.0909090909% {
    transform: translateX(19vw) translateY(4vh) scale(0.48);
  }
  13.6363636364% {
    transform: translateX(40vw) translateY(37vh) scale(0.55);
  }
  18.1818181818% {
    transform: translateX(-33vw) translateY(16vh) scale(0.29);
  }
  22.7272727273% {
    transform: translateX(8vw) translateY(33vh) scale(0.38);
  }
  27.2727272727% {
    transform: translateX(-2vw) translateY(-7vh) scale(0.99);
  }
  31.8181818182% {
    transform: translateX(-11vw) translateY(31vh) scale(0.81);
  }
  36.3636363636% {
    transform: translateX(-28vw) translateY(-19vh) scale(0.85);
  }
  40.9090909091% {
    transform: translateX(-34vw) translateY(-14vh) scale(0.42);
  }
  45.4545454545% {
    transform: translateX(19vw) translateY(49vh) scale(0.74);
  }
  50% {
    transform: translateX(9vw) translateY(11vh) scale(0.36);
  }
  54.5454545455% {
    transform: translateX(-45vw) translateY(-18vh) scale(0.42);
  }
  59.0909090909% {
    transform: translateX(37vw) translateY(-14vh) scale(0.39);
  }
  63.6363636364% {
    transform: translateX(42vw) translateY(-44vh) scale(0.89);
  }
  68.1818181818% {
    transform: translateX(-18vw) translateY(17vh) scale(0.89);
  }
  72.7272727273% {
    transform: translateX(-7vw) translateY(-32vh) scale(0.38);
  }
  77.2727272727% {
    transform: translateX(-20vw) translateY(-14vh) scale(0.77);
  }
  81.8181818182% {
    transform: translateX(-14vw) translateY(-17vh) scale(0.77);
  }
  86.3636363636% {
    transform: translateX(45vw) translateY(27vh) scale(0.94);
  }
  90.9090909091% {
    transform: translateX(25vw) translateY(-16vh) scale(0.34);
  }
  95.4545454545% {
    transform: translateX(11vw) translateY(-38vh) scale(0.83);
  }
  100% {
    transform: translateX(23vw) translateY(-39vh) scale(0.56);
  }
}
.firefly:nth-child(40) {
  animation-name: move40;
}
.firefly:nth-child(40)::before {
  animation-duration: 14s;
}
.firefly:nth-child(40)::after {
  animation-duration: 14s, 10699ms;
  animation-delay: 0ms, 1483ms;
}

@keyframes move40 {
  0% {
    transform: translateX(46vw) translateY(-36vh) scale(0.52);
  }
  3.5714285714% {
    transform: translateX(-21vw) translateY(28vh) scale(0.54);
  }
  7.1428571429% {
    transform: translateX(48vw) translateY(48vh) scale(0.41);
  }
  10.7142857143% {
    transform: translateX(-22vw) translateY(21vh) scale(1);
  }
  14.2857142857% {
    transform: translateX(31vw) translateY(17vh) scale(0.29);
  }
  17.8571428571% {
    transform: translateX(45vw) translateY(33vh) scale(0.39);
  }
  21.4285714286% {
    transform: translateX(-31vw) translateY(41vh) scale(0.46);
  }
  25% {
    transform: translateX(-15vw) translateY(15vh) scale(0.59);
  }
  28.5714285714% {
    transform: translateX(11vw) translateY(6vh) scale(0.75);
  }
  32.1428571429% {
    transform: translateX(-35vw) translateY(45vh) scale(0.44);
  }
  35.7142857143% {
    transform: translateX(20vw) translateY(-24vh) scale(0.29);
  }
  39.2857142857% {
    transform: translateX(16vw) translateY(-11vh) scale(0.64);
  }
  42.8571428571% {
    transform: translateX(-26vw) translateY(7vh) scale(0.72);
  }
  46.4285714286% {
    transform: translateX(-3vw) translateY(10vh) scale(0.3);
  }
  50% {
    transform: translateX(-33vw) translateY(21vh) scale(0.66);
  }
  53.5714285714% {
    transform: translateX(33vw) translateY(-31vh) scale(0.97);
  }
  57.1428571429% {
    transform: translateX(-23vw) translateY(22vh) scale(0.71);
  }
  60.7142857143% {
    transform: translateX(-1vw) translateY(49vh) scale(0.66);
  }
  64.2857142857% {
    transform: translateX(33vw) translateY(-12vh) scale(0.44);
  }
  67.8571428571% {
    transform: translateX(13vw) translateY(38vh) scale(0.51);
  }
  71.4285714286% {
    transform: translateX(27vw) translateY(24vh) scale(0.32);
  }
  75% {
    transform: translateX(9vw) translateY(26vh) scale(0.58);
  }
  78.5714285714% {
    transform: translateX(-2vw) translateY(8vh) scale(0.52);
  }
  82.1428571429% {
    transform: translateX(-38vw) translateY(35vh) scale(0.32);
  }
  85.7142857143% {
    transform: translateX(42vw) translateY(11vh) scale(0.48);
  }
  89.2857142857% {
    transform: translateX(-47vw) translateY(-35vh) scale(0.32);
  }
  92.8571428571% {
    transform: translateX(-40vw) translateY(-41vh) scale(0.63);
  }
  96.4285714286% {
    transform: translateX(16vw) translateY(-30vh) scale(0.29);
  }
  100% {
    transform: translateX(25vw) translateY(29vh) scale(1);
  }
}
.firefly:nth-child(41) {
  animation-name: move41;
}
.firefly:nth-child(41)::before {
  animation-duration: 15s;
}
.firefly:nth-child(41)::after {
  animation-duration: 15s, 6613ms;
  animation-delay: 0ms, 7247ms;
}

@keyframes move41 {
  0% {
    transform: translateX(-26vw) translateY(47vh) scale(0.68);
  }
  3.5714285714% {
    transform: translateX(10vw) translateY(-24vh) scale(0.64);
  }
  7.1428571429% {
    transform: translateX(27vw) translateY(-19vh) scale(1);
  }
  10.7142857143% {
    transform: translateX(13vw) translateY(49vh) scale(0.34);
  }
  14.2857142857% {
    transform: translateX(41vw) translateY(-37vh) scale(0.69);
  }
  17.8571428571% {
    transform: translateX(-9vw) translateY(-13vh) scale(0.62);
  }
  21.4285714286% {
    transform: translateX(18vw) translateY(34vh) scale(0.64);
  }
  25% {
    transform: translateX(-18vw) translateY(9vh) scale(0.74);
  }
  28.5714285714% {
    transform: translateX(34vw) translateY(-7vh) scale(0.71);
  }
  32.1428571429% {
    transform: translateX(-21vw) translateY(-28vh) scale(0.84);
  }
  35.7142857143% {
    transform: translateX(8vw) translateY(-28vh) scale(0.94);
  }
  39.2857142857% {
    transform: translateX(-49vw) translateY(-21vh) scale(0.39);
  }
  42.8571428571% {
    transform: translateX(3vw) translateY(-42vh) scale(0.81);
  }
  46.4285714286% {
    transform: translateX(-43vw) translateY(-9vh) scale(0.36);
  }
  50% {
    transform: translateX(13vw) translateY(44vh) scale(0.58);
  }
  53.5714285714% {
    transform: translateX(-25vw) translateY(47vh) scale(1);
  }
  57.1428571429% {
    transform: translateX(45vw) translateY(-39vh) scale(0.5);
  }
  60.7142857143% {
    transform: translateX(-9vw) translateY(44vh) scale(0.54);
  }
  64.2857142857% {
    transform: translateX(-25vw) translateY(35vh) scale(0.52);
  }
  67.8571428571% {
    transform: translateX(9vw) translateY(35vh) scale(0.47);
  }
  71.4285714286% {
    transform: translateX(46vw) translateY(35vh) scale(0.64);
  }
  75% {
    transform: translateX(-36vw) translateY(-21vh) scale(0.84);
  }
  78.5714285714% {
    transform: translateX(-44vw) translateY(-23vh) scale(0.45);
  }
  82.1428571429% {
    transform: translateX(-14vw) translateY(42vh) scale(0.92);
  }
  85.7142857143% {
    transform: translateX(-38vw) translateY(-28vh) scale(0.49);
  }
  89.2857142857% {
    transform: translateX(24vw) translateY(-48vh) scale(0.66);
  }
  92.8571428571% {
    transform: translateX(-23vw) translateY(-37vh) scale(0.86);
  }
  96.4285714286% {
    transform: translateX(-4vw) translateY(-17vh) scale(0.58);
  }
  100% {
    transform: translateX(-11vw) translateY(-26vh) scale(0.83);
  }
}
.firefly:nth-child(42) {
  animation-name: move42;
}
.firefly:nth-child(42)::before {
  animation-duration: 13s;
}
.firefly:nth-child(42)::after {
  animation-duration: 13s, 10570ms;
  animation-delay: 0ms, 5908ms;
}

@keyframes move42 {
  0% {
    transform: translateX(25vw) translateY(-46vh) scale(0.41);
  }
  4.1666666667% {
    transform: translateX(48vw) translateY(-27vh) scale(0.85);
  }
  8.3333333333% {
    transform: translateX(3vw) translateY(6vh) scale(0.26);
  }
  12.5% {
    transform: translateX(-33vw) translateY(-39vh) scale(0.87);
  }
  16.6666666667% {
    transform: translateX(-13vw) translateY(41vh) scale(0.51);
  }
  20.8333333333% {
    transform: translateX(-41vw) translateY(4vh) scale(0.51);
  }
  25% {
    transform: translateX(6vw) translateY(-44vh) scale(0.74);
  }
  29.1666666667% {
    transform: translateX(37vw) translateY(-48vh) scale(0.28);
  }
  33.3333333333% {
    transform: translateX(17vw) translateY(-1vh) scale(0.49);
  }
  37.5% {
    transform: translateX(-17vw) translateY(-3vh) scale(0.28);
  }
  41.6666666667% {
    transform: translateX(1vw) translateY(19vh) scale(0.49);
  }
  45.8333333333% {
    transform: translateX(25vw) translateY(3vh) scale(0.5);
  }
  50% {
    transform: translateX(17vw) translateY(36vh) scale(0.87);
  }
  54.1666666667% {
    transform: translateX(-37vw) translateY(-2vh) scale(0.58);
  }
  58.3333333333% {
    transform: translateX(2vw) translateY(-22vh) scale(0.66);
  }
  62.5% {
    transform: translateX(9vw) translateY(-34vh) scale(0.68);
  }
  66.6666666667% {
    transform: translateX(31vw) translateY(-37vh) scale(0.94);
  }
  70.8333333333% {
    transform: translateX(-12vw) translateY(15vh) scale(0.53);
  }
  75% {
    transform: translateX(-33vw) translateY(24vh) scale(0.29);
  }
  79.1666666667% {
    transform: translateX(29vw) translateY(-7vh) scale(0.63);
  }
  83.3333333333% {
    transform: translateX(-32vw) translateY(-44vh) scale(0.62);
  }
  87.5% {
    transform: translateX(-26vw) translateY(-24vh) scale(0.66);
  }
  91.6666666667% {
    transform: translateX(-10vw) translateY(-18vh) scale(0.45);
  }
  95.8333333333% {
    transform: translateX(-13vw) translateY(-13vh) scale(0.93);
  }
  100% {
    transform: translateX(-38vw) translateY(-28vh) scale(0.43);
  }
}
.firefly:nth-child(43) {
  animation-name: move43;
}
.firefly:nth-child(43)::before {
  animation-duration: 12s;
}
.firefly:nth-child(43)::after {
  animation-duration: 12s, 6170ms;
  animation-delay: 0ms, 4489ms;
}

@keyframes move43 {
  0% {
    transform: translateX(-35vw) translateY(45vh) scale(0.93);
  }
  5.8823529412% {
    transform: translateX(5vw) translateY(11vh) scale(0.76);
  }
  11.7647058824% {
    transform: translateX(26vw) translateY(15vh) scale(0.3);
  }
  17.6470588235% {
    transform: translateX(36vw) translateY(21vh) scale(0.49);
  }
  23.5294117647% {
    transform: translateX(-18vw) translateY(-47vh) scale(0.3);
  }
  29.4117647059% {
    transform: translateX(6vw) translateY(31vh) scale(0.61);
  }
  35.2941176471% {
    transform: translateX(-28vw) translateY(42vh) scale(0.55);
  }
  41.1764705882% {
    transform: translateX(8vw) translateY(32vh) scale(0.86);
  }
  47.0588235294% {
    transform: translateX(-10vw) translateY(-13vh) scale(0.76);
  }
  52.9411764706% {
    transform: translateX(-20vw) translateY(21vh) scale(0.58);
  }
  58.8235294118% {
    transform: translateX(3vw) translateY(-44vh) scale(0.87);
  }
  64.7058823529% {
    transform: translateX(-18vw) translateY(-27vh) scale(0.43);
  }
  70.5882352941% {
    transform: translateX(-8vw) translateY(-18vh) scale(0.98);
  }
  76.4705882353% {
    transform: translateX(-18vw) translateY(48vh) scale(0.71);
  }
  82.3529411765% {
    transform: translateX(31vw) translateY(20vh) scale(0.76);
  }
  88.2352941176% {
    transform: translateX(16vw) translateY(-44vh) scale(0.37);
  }
  94.1176470588% {
    transform: translateX(11vw) translateY(48vh) scale(0.77);
  }
  100% {
    transform: translateX(-7vw) translateY(-25vh) scale(0.67);
  }
}
.firefly:nth-child(44) {
  animation-name: move44;
}
.firefly:nth-child(44)::before {
  animation-duration: 18s;
}
.firefly:nth-child(44)::after {
  animation-duration: 18s, 6739ms;
  animation-delay: 0ms, 7518ms;
}

@keyframes move44 {
  0% {
    transform: translateX(-15vw) translateY(-20vh) scale(0.89);
  }
  3.5714285714% {
    transform: translateX(-31vw) translateY(-43vh) scale(0.92);
  }
  7.1428571429% {
    transform: translateX(-46vw) translateY(-46vh) scale(0.67);
  }
  10.7142857143% {
    transform: translateX(-4vw) translateY(-44vh) scale(0.44);
  }
  14.2857142857% {
    transform: translateX(27vw) translateY(-9vh) scale(0.87);
  }
  17.8571428571% {
    transform: translateX(-11vw) translateY(-31vh) scale(0.75);
  }
  21.4285714286% {
    transform: translateX(49vw) translateY(35vh) scale(0.91);
  }
  25% {
    transform: translateX(49vw) translateY(48vh) scale(0.98);
  }
  28.5714285714% {
    transform: translateX(-14vw) translateY(3vh) scale(0.52);
  }
  32.1428571429% {
    transform: translateX(-30vw) translateY(45vh) scale(0.41);
  }
  35.7142857143% {
    transform: translateX(22vw) translateY(-25vh) scale(0.56);
  }
  39.2857142857% {
    transform: translateX(-16vw) translateY(39vh) scale(0.39);
  }
  42.8571428571% {
    transform: translateX(-36vw) translateY(1vh) scale(0.77);
  }
  46.4285714286% {
    transform: translateX(4vw) translateY(30vh) scale(0.48);
  }
  50% {
    transform: translateX(-40vw) translateY(-12vh) scale(0.88);
  }
  53.5714285714% {
    transform: translateX(-36vw) translateY(8vh) scale(0.58);
  }
  57.1428571429% {
    transform: translateX(-49vw) translateY(-36vh) scale(0.75);
  }
  60.7142857143% {
    transform: translateX(11vw) translateY(37vh) scale(0.35);
  }
  64.2857142857% {
    transform: translateX(4vw) translateY(-5vh) scale(0.56);
  }
  67.8571428571% {
    transform: translateX(14vw) translateY(4vh) scale(0.94);
  }
  71.4285714286% {
    transform: translateX(-3vw) translateY(46vh) scale(0.57);
  }
  75% {
    transform: translateX(25vw) translateY(-34vh) scale(0.3);
  }
  78.5714285714% {
    transform: translateX(29vw) translateY(-7vh) scale(0.95);
  }
  82.1428571429% {
    transform: translateX(28vw) translateY(36vh) scale(1);
  }
  85.7142857143% {
    transform: translateX(27vw) translateY(49vh) scale(0.44);
  }
  89.2857142857% {
    transform: translateX(21vw) translateY(-38vh) scale(0.32);
  }
  92.8571428571% {
    transform: translateX(46vw) translateY(36vh) scale(0.36);
  }
  96.4285714286% {
    transform: translateX(-11vw) translateY(36vh) scale(0.85);
  }
  100% {
    transform: translateX(18vw) translateY(16vh) scale(0.77);
  }
}
.firefly:nth-child(45) {
  animation-name: move45;
}
.firefly:nth-child(45)::before {
  animation-duration: 11s;
}
.firefly:nth-child(45)::after {
  animation-duration: 11s, 10799ms;
  animation-delay: 0ms, 4084ms;
}

@keyframes move45 {
  0% {
    transform: translateX(38vw) translateY(-14vh) scale(0.79);
  }
  5.5555555556% {
    transform: translateX(20vw) translateY(33vh) scale(0.51);
  }
  11.1111111111% {
    transform: translateX(-36vw) translateY(22vh) scale(0.46);
  }
  16.6666666667% {
    transform: translateX(15vw) translateY(-25vh) scale(0.37);
  }
  22.2222222222% {
    transform: translateX(13vw) translateY(36vh) scale(0.26);
  }
  27.7777777778% {
    transform: translateX(-39vw) translateY(-41vh) scale(0.32);
  }
  33.3333333333% {
    transform: translateX(-12vw) translateY(45vh) scale(0.91);
  }
  38.8888888889% {
    transform: translateX(35vw) translateY(-37vh) scale(0.52);
  }
  44.4444444444% {
    transform: translateX(-38vw) translateY(6vh) scale(0.35);
  }
  50% {
    transform: translateX(35vw) translateY(11vh) scale(0.62);
  }
  55.5555555556% {
    transform: translateX(-13vw) translateY(-30vh) scale(0.56);
  }
  61.1111111111% {
    transform: translateX(-16vw) translateY(30vh) scale(0.69);
  }
  66.6666666667% {
    transform: translateX(-3vw) translateY(33vh) scale(0.35);
  }
  72.2222222222% {
    transform: translateX(-20vw) translateY(13vh) scale(0.97);
  }
  77.7777777778% {
    transform: translateX(-4vw) translateY(49vh) scale(0.83);
  }
  83.3333333333% {
    transform: translateX(7vw) translateY(38vh) scale(0.43);
  }
  88.8888888889% {
    transform: translateX(28vw) translateY(44vh) scale(0.49);
  }
  94.4444444444% {
    transform: translateX(-31vw) translateY(21vh) scale(0.61);
  }
  100% {
    transform: translateX(37vw) translateY(11vh) scale(0.86);
  }
}
.firefly:nth-child(46) {
  animation-name: move46;
}
.firefly:nth-child(46)::before {
  animation-duration: 16s;
}
.firefly:nth-child(46)::after {
  animation-duration: 16s, 5287ms;
  animation-delay: 0ms, 7389ms;
}

@keyframes move46 {
  0% {
    transform: translateX(-36vw) translateY(-42vh) scale(0.62);
  }
  3.7037037037% {
    transform: translateX(0vw) translateY(48vh) scale(0.78);
  }
  7.4074074074% {
    transform: translateX(50vw) translateY(32vh) scale(0.65);
  }
  11.1111111111% {
    transform: translateX(21vw) translateY(40vh) scale(0.56);
  }
  14.8148148148% {
    transform: translateX(49vw) translateY(-26vh) scale(0.49);
  }
  18.5185185185% {
    transform: translateX(31vw) translateY(10vh) scale(0.97);
  }
  22.2222222222% {
    transform: translateX(-23vw) translateY(6vh) scale(0.33);
  }
  25.9259259259% {
    transform: translateX(-39vw) translateY(-20vh) scale(0.78);
  }
  29.6296296296% {
    transform: translateX(-20vw) translateY(-1vh) scale(0.71);
  }
  33.3333333333% {
    transform: translateX(-42vw) translateY(-22vh) scale(0.26);
  }
  37.037037037% {
    transform: translateX(-44vw) translateY(46vh) scale(0.3);
  }
  40.7407407407% {
    transform: translateX(30vw) translateY(-10vh) scale(0.87);
  }
  44.4444444444% {
    transform: translateX(-4vw) translateY(39vh) scale(0.8);
  }
  48.1481481481% {
    transform: translateX(-3vw) translateY(-46vh) scale(0.43);
  }
  51.8518518519% {
    transform: translateX(-46vw) translateY(17vh) scale(0.95);
  }
  55.5555555556% {
    transform: translateX(-42vw) translateY(-15vh) scale(0.49);
  }
  59.2592592593% {
    transform: translateX(-21vw) translateY(20vh) scale(0.47);
  }
  62.962962963% {
    transform: translateX(33vw) translateY(-9vh) scale(0.31);
  }
  66.6666666667% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.93);
  }
  70.3703703704% {
    transform: translateX(-2vw) translateY(-48vh) scale(0.91);
  }
  74.0740740741% {
    transform: translateX(16vw) translateY(-38vh) scale(0.69);
  }
  77.7777777778% {
    transform: translateX(45vw) translateY(-31vh) scale(0.98);
  }
  81.4814814815% {
    transform: translateX(-35vw) translateY(45vh) scale(0.35);
  }
  85.1851851852% {
    transform: translateX(-47vw) translateY(14vh) scale(0.73);
  }
  88.8888888889% {
    transform: translateX(44vw) translateY(17vh) scale(0.7);
  }
  92.5925925926% {
    transform: translateX(33vw) translateY(-42vh) scale(0.35);
  }
  96.2962962963% {
    transform: translateX(-34vw) translateY(15vh) scale(0.72);
  }
  100% {
    transform: translateX(6vw) translateY(29vh) scale(0.67);
  }
}
.firefly:nth-child(47) {
  animation-name: move47;
}
.firefly:nth-child(47)::before {
  animation-duration: 13s;
}
.firefly:nth-child(47)::after {
  animation-duration: 13s, 5949ms;
  animation-delay: 0ms, 6202ms;
}

@keyframes move47 {
  0% {
    transform: translateX(-35vw) translateY(-16vh) scale(0.7);
  }
  4% {
    transform: translateX(9vw) translateY(18vh) scale(1);
  }
  8% {
    transform: translateX(-23vw) translateY(-35vh) scale(0.65);
  }
  12% {
    transform: translateX(-44vw) translateY(-45vh) scale(0.54);
  }
  16% {
    transform: translateX(50vw) translateY(-28vh) scale(0.75);
  }
  20% {
    transform: translateX(-9vw) translateY(-40vh) scale(0.36);
  }
  24% {
    transform: translateX(22vw) translateY(0vh) scale(0.83);
  }
  28% {
    transform: translateX(5vw) translateY(47vh) scale(0.65);
  }
  32% {
    transform: translateX(50vw) translateY(-20vh) scale(0.68);
  }
  36% {
    transform: translateX(6vw) translateY(-36vh) scale(0.97);
  }
  40% {
    transform: translateX(-14vw) translateY(-24vh) scale(0.85);
  }
  44% {
    transform: translateX(39vw) translateY(22vh) scale(0.95);
  }
  48% {
    transform: translateX(-4vw) translateY(3vh) scale(0.77);
  }
  52% {
    transform: translateX(-19vw) translateY(-28vh) scale(0.26);
  }
  56% {
    transform: translateX(40vw) translateY(39vh) scale(0.92);
  }
  60% {
    transform: translateX(3vw) translateY(-9vh) scale(0.54);
  }
  64% {
    transform: translateX(49vw) translateY(-45vh) scale(0.66);
  }
  68% {
    transform: translateX(40vw) translateY(-31vh) scale(0.72);
  }
  72% {
    transform: translateX(13vw) translateY(-5vh) scale(0.68);
  }
  76% {
    transform: translateX(-14vw) translateY(-43vh) scale(0.73);
  }
  80% {
    transform: translateX(4vw) translateY(-10vh) scale(0.87);
  }
  84% {
    transform: translateX(-16vw) translateY(-45vh) scale(0.57);
  }
  88% {
    transform: translateX(-16vw) translateY(-10vh) scale(0.59);
  }
  92% {
    transform: translateX(-13vw) translateY(-39vh) scale(0.85);
  }
  96% {
    transform: translateX(23vw) translateY(-13vh) scale(0.69);
  }
  100% {
    transform: translateX(-34vw) translateY(-32vh) scale(0.85);
  }
}
.firefly:nth-child(48) {
  animation-name: move48;
}
.firefly:nth-child(48)::before {
  animation-duration: 13s;
}
.firefly:nth-child(48)::after {
  animation-duration: 13s, 9971ms;
  animation-delay: 0ms, 5838ms;
}

@keyframes move48 {
  0% {
    transform: translateX(9vw) translateY(-13vh) scale(0.64);
  }
  3.5714285714% {
    transform: translateX(-28vw) translateY(-14vh) scale(0.42);
  }
  7.1428571429% {
    transform: translateX(-49vw) translateY(-22vh) scale(0.28);
  }
  10.7142857143% {
    transform: translateX(45vw) translateY(45vh) scale(0.3);
  }
  14.2857142857% {
    transform: translateX(-43vw) translateY(35vh) scale(0.55);
  }
  17.8571428571% {
    transform: translateX(-46vw) translateY(45vh) scale(0.43);
  }
  21.4285714286% {
    transform: translateX(37vw) translateY(-37vh) scale(0.4);
  }
  25% {
    transform: translateX(40vw) translateY(8vh) scale(0.39);
  }
  28.5714285714% {
    transform: translateX(-25vw) translateY(-47vh) scale(0.75);
  }
  32.1428571429% {
    transform: translateX(-37vw) translateY(18vh) scale(0.26);
  }
  35.7142857143% {
    transform: translateX(-26vw) translateY(-23vh) scale(0.62);
  }
  39.2857142857% {
    transform: translateX(38vw) translateY(-48vh) scale(0.48);
  }
  42.8571428571% {
    transform: translateX(5vw) translateY(23vh) scale(0.99);
  }
  46.4285714286% {
    transform: translateX(8vw) translateY(-5vh) scale(0.51);
  }
  50% {
    transform: translateX(-46vw) translateY(-24vh) scale(0.33);
  }
  53.5714285714% {
    transform: translateX(16vw) translateY(15vh) scale(0.58);
  }
  57.1428571429% {
    transform: translateX(-29vw) translateY(-2vh) scale(0.93);
  }
  60.7142857143% {
    transform: translateX(2vw) translateY(-14vh) scale(0.96);
  }
  64.2857142857% {
    transform: translateX(7vw) translateY(31vh) scale(0.68);
  }
  67.8571428571% {
    transform: translateX(-19vw) translateY(45vh) scale(0.31);
  }
  71.4285714286% {
    transform: translateX(-33vw) translateY(-13vh) scale(0.54);
  }
  75% {
    transform: translateX(-27vw) translateY(29vh) scale(0.44);
  }
  78.5714285714% {
    transform: translateX(-29vw) translateY(50vh) scale(0.54);
  }
  82.1428571429% {
    transform: translateX(15vw) translateY(-22vh) scale(0.8);
  }
  85.7142857143% {
    transform: translateX(21vw) translateY(6vh) scale(0.46);
  }
  89.2857142857% {
    transform: translateX(-19vw) translateY(28vh) scale(0.88);
  }
  92.8571428571% {
    transform: translateX(-28vw) translateY(-19vh) scale(0.96);
  }
  96.4285714286% {
    transform: translateX(25vw) translateY(41vh) scale(0.99);
  }
  100% {
    transform: translateX(26vw) translateY(22vh) scale(0.32);
  }
}
.firefly:nth-child(49) {
  animation-name: move49;
}
.firefly:nth-child(49)::before {
  animation-duration: 16s;
}
.firefly:nth-child(49)::after {
  animation-duration: 16s, 7525ms;
  animation-delay: 0ms, 4705ms;
}

@keyframes move49 {
  0% {
    transform: translateX(15vw) translateY(-23vh) scale(0.35);
  }
  4.7619047619% {
    transform: translateX(41vw) translateY(-10vh) scale(0.55);
  }
  9.5238095238% {
    transform: translateX(32vw) translateY(25vh) scale(0.84);
  }
  14.2857142857% {
    transform: translateX(11vw) translateY(-3vh) scale(0.36);
  }
  19.0476190476% {
    transform: translateX(10vw) translateY(-31vh) scale(0.4);
  }
  23.8095238095% {
    transform: translateX(47vw) translateY(-41vh) scale(0.48);
  }
  28.5714285714% {
    transform: translateX(9vw) translateY(-44vh) scale(0.87);
  }
  33.3333333333% {
    transform: translateX(27vw) translateY(-45vh) scale(0.55);
  }
  38.0952380952% {
    transform: translateX(-40vw) translateY(-45vh) scale(0.36);
  }
  42.8571428571% {
    transform: translateX(-10vw) translateY(28vh) scale(0.58);
  }
  47.619047619% {
    transform: translateX(30vw) translateY(-3vh) scale(0.73);
  }
  52.380952381% {
    transform: translateX(17vw) translateY(0vh) scale(0.72);
  }
  57.1428571429% {
    transform: translateX(-29vw) translateY(-16vh) scale(0.35);
  }
  61.9047619048% {
    transform: translateX(-31vw) translateY(-16vh) scale(0.34);
  }
  66.6666666667% {
    transform: translateX(35vw) translateY(3vh) scale(0.63);
  }
  71.4285714286% {
    transform: translateX(17vw) translateY(-11vh) scale(0.48);
  }
  76.1904761905% {
    transform: translateX(-22vw) translateY(21vh) scale(0.61);
  }
  80.9523809524% {
    transform: translateX(7vw) translateY(35vh) scale(0.94);
  }
  85.7142857143% {
    transform: translateX(50vw) translateY(37vh) scale(0.99);
  }
  90.4761904762% {
    transform: translateX(-27vw) translateY(35vh) scale(0.5);
  }
  95.2380952381% {
    transform: translateX(-9vw) translateY(-46vh) scale(0.69);
  }
  100% {
    transform: translateX(-1vw) translateY(-19vh) scale(0.88);
  }
}
.firefly:nth-child(50) {
  animation-name: move50;
}
.firefly:nth-child(50)::before {
  animation-duration: 12s;
}
.firefly:nth-child(50)::after {
  animation-duration: 12s, 10786ms;
  animation-delay: 0ms, 2740ms;
}

@keyframes move50 {
  0% {
    transform: translateX(-36vw) translateY(8vh) scale(0.81);
  }
  5.2631578947% {
    transform: translateX(23vw) translateY(-3vh) scale(0.79);
  }
  10.5263157895% {
    transform: translateX(22vw) translateY(5vh) scale(0.52);
  }
  15.7894736842% {
    transform: translateX(4vw) translateY(12vh) scale(0.71);
  }
  21.0526315789% {
    transform: translateX(44vw) translateY(33vh) scale(0.37);
  }
  26.3157894737% {
    transform: translateX(-12vw) translateY(28vh) scale(0.43);
  }
  31.5789473684% {
    transform: translateX(4vw) translateY(25vh) scale(0.71);
  }
  36.8421052632% {
    transform: translateX(46vw) translateY(15vh) scale(0.65);
  }
  42.1052631579% {
    transform: translateX(-26vw) translateY(-5vh) scale(1);
  }
  47.3684210526% {
    transform: translateX(32vw) translateY(37vh) scale(0.93);
  }
  52.6315789474% {
    transform: translateX(-40vw) translateY(-31vh) scale(0.47);
  }
  57.8947368421% {
    transform: translateX(-26vw) translateY(2vh) scale(0.29);
  }
  63.1578947368% {
    transform: translateX(22vw) translateY(4vh) scale(0.51);
  }
  68.4210526316% {
    transform: translateX(-31vw) translateY(34vh) scale(0.66);
  }
  73.6842105263% {
    transform: translateX(-10vw) translateY(1vh) scale(0.34);
  }
  78.9473684211% {
    transform: translateX(-33vw) translateY(-21vh) scale(0.99);
  }
  84.2105263158% {
    transform: translateX(15vw) translateY(-17vh) scale(0.79);
  }
  89.4736842105% {
    transform: translateX(25vw) translateY(0vh) scale(0.7);
  }
  94.7368421053% {
    transform: translateX(-30vw) translateY(25vh) scale(0.31);
  }
  100% {
    transform: translateX(-46vw) translateY(14vh) scale(0.47);
  }
}
@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}