@import url("https://fonts.googleapis.com/css2?family=Benne&display=swap");
@font-face {
  font-family: formFont;
  src: url("fonts/RomandeADFStyleStd-DemiBold.otf");
}
@font-face {
  font-family: answerFont;
  src: url("fonts/RomandeADFStd-Regular.otf");
}
.Profile {
  width: 500px;
}
.custom-range{
  color: black
}
.button-edit .btn {
  width: 100%;
  color: #a49d9d;
  border: 4px solid #3b3b3b;
  font-size: 1.15rem;
  background: #eeecec;
  color: #444343;
}
.button-edit .wrap .btn:hover {
  box-shadow: 1px 1px 25px 10px rgb(66 49 25 / 40%);
  color: rgb(102 102 119);
}

@media only screen and (max-width: 500px) {
  .Profile {
    width: 350px;
    font-size: 0.8rem;
  }
}

.edit_form_element {
  display: flex;
  flex-direction: column;
}

.Profile .profile_photo {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.Profile .photo {
  border-radius: 50%;
  transform: scale(1.3);
  border: 2px groove rgb(235, 221, 197);
}

.Profile .title {
  font-family: formFont;
  /* font-family: "Noto Serif", serif; */
  font-weight: bold;
  font-size: .95rem;
}

@media (min-width: 768px) {
  .Profile .photo {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .Profile {
    width: 300px;
    font-size: 0.7rem;
  }
  .Profile .title {
    font-size: 0.6rem;
  }
  .Profile .photo {
    border-radius: 50%;
    transform: scale(1.1);
    border: 2px groove rgb(235, 221, 197);
  }
}
