.Loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.Loader .image_loader {
  height: 300px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

.Loader .my-node-exit-done{
  display: none;
}