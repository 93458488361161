@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@800&display=swap");

/*@font-face {
  font-family: auditionFont;
  src: url("fonts/Hodor.ttf");
} */

@font-face {
  font-family: auditionFont;
  src: url("fonts/Stranger\ Things\ Outlined.ttf") format("truetype");
}

@font-face {
  font-family: auditionFonto;
  src: url("fonts/Stranger\ Things.ttf") format("truetype");
}


/* .test {
  animation: squiggly-anim 0.34s linear infinite;
} */
.test {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  outline: none;
  text-align: center;
  line-height: 1.5;
  font-size: 5rem;
  font-family: auditionFont;
  font-weight: bolder;
  color: #e7e7de;
  transform: translateY(3rem);
}
@media only screen and (max-width: 600px) {
  .test {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 300px) {
  .test {
    font-size: 2rem;
  }
}

.small {
  font-size: 0.5em;
}
.smaller {
  font-size: 0.4em;
}
p {
  margin: 0;
}
@-moz-keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}
@-webkit-keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}
@-o-keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}
@keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}

.main-text-effect {
  /* background-image: linear-gradient(#ffe470, #a56600);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #ff1515;
  text-shadow: 0 0 5px #ff1515, 0 0 10px #383836, 0 0 15px #383836, 0 0 20px #383836, 0 0 25px #383836, 0 0 30px #383836, 0 0 35px #383836;
  letter-spacing: 0.3rem;
  }
