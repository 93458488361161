.ProfileEdit .form_edit {
  width: 400px;
}
.Profile {
  margin-bottom: 40px;
}
.ProfileEdit .title {
  font-family: "Benne", serif;
  font-weight: 700;
  font-size: 1.05rem;
}

@media only screen and (max-width: 400px) {
  .ProfileEdit .form_edit {
    width: 300px;
    font-size: 0.8rem;
  }
}
