@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');
.office-meme {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 11px;
  font-family: 'Cinzel', serif;
  font-weight: bold;
  font-size: 1.1rem;
  background-color: #a49d9d;
  border: 2px solid #696862;
  padding: 18px;
}

.office-meme .image {
  max-height: 55vh;
  object-fit: contain;
  padding: 10px;
  padding-top : 0;
}
