

.collect-response .image {
  max-width: 40vw;
  max-height: 35vh;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 20px;
}

.collect-response .submitted {
  padding: 30px;
  border-radius: 11px;
  font-family: formFont;
  font-size: .8rem;
}

@media only screen and (min-width: 453px) {
  .collect-response .submitted {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 382px) {
  .collect-response .submitted {
    font-size: 0.5rem;
  }
}

.horizontally_center_items {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-content {
  background-color: rgba(253, 251, 247);
}
